import {useState, useEffect} from "react";
import "../CRM.css";
import "./charts.css";
import TopBar from "../TopBar";
import SideNav from "../SideNav";
import ChartBrent from "./chartBrent";
import ChartDavid from "./chartDavid";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Dashboard() {

  var grc_options = [
    {label: "CCKHKN", value: "CCKHKN"},
    {label: "KYM", value: "KYM"},
    {label: "NS", value: "NS"},
    {label: "WCP", value: "WCP"}
  ];

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

      const [filtered_grc_options, set_filtered_grc_options] = useState(grc_options);


  useEffect(() => {
    const fetchData = async () => {
      let response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}/analytics2/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.sessiontoken
          }
        }
      );
      const json = await response.json();
      console.log(json.data)
      console.log(sessionStorage.user_level)
      console.log(sessionStorage.myGRC)
      if (sessionStorage.user_level>0) {
        var _data = json.data.filter(d=>{return d.grc.toUpperCase()==sessionStorage.myGRC.toUpperCase()})
        setData(_data);
set_filtered_grc_options(grc_options.filter(d=>{return d.value.toUpperCase()==sessionStorage.myGRC.toUpperCase()}))

      }
else {setData(json.data);

}

      setLoading(false);
    };

    fetchData().catch(console.error);
  }, [setLoading]);
  return (
    <div className="crm-container">
      <div className="container">
        <TopBar where="Dashboard" />
        <div className="side-and-content">
          <div>
            <SideNav active="Dashboard" />
          </div>
          <div className="content-box">
            <div className="dashboard-chart-container">
              <ChartBrent data={data} options={filtered_grc_options} />


            </div>
                <ChartDavid data={data}  options={filtered_grc_options} />
                <br />
                <br />
          </div>
        </div>
      </div>
    </div>
  );
}
