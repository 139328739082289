import Layout from "layouts";
import { PageHeader } from "antd";
import followUp from "./media/icon.svg";
import { routes } from "routes";
import { Link } from "react-router-dom";

import "./event.less";
const MainEventPage = () => {



  return (
    <Layout className="main-event-page" selectedKey="event">
      <PageHeader
        ghost={false}
        onBack={false}
        title="CDSS: Event"
      ></PageHeader>


      <div className="menu-box">

        <Link to="/crm">
          <div className='option'>

            <h2 className="title">
              Create Event
            </h2>
          </div>
        </Link>

        <Link to="/event_table">
          <div className='option'>

            <h2 className="title">
              Mark Attendance
            </h2>
          </div>
        </Link>

      </div>

    </Layout>
  );
};


export default MainEventPage;