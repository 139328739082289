import {useState, useEffect, useRef} from "react";
import "./containers.css";
import {
  faPlus,
  faAngleDown,
  faAngleRight,
  faMinus
} from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function Dropbox(props) {
  var {content} = props;
  const [myopen, setOpen] = useState(false);
  const toggleopen = () => {
  
    setOpen(!myopen);
  };

  const shorten = text => {
    if (myopen) return text;
    else {
      if (text) return text.substring(0, 20) + " ...";
      else return text;
    }
  };
  return (
    <div>
    <div className="drop-box">
      {content ? (
        <Button onClick={toggleopen}>
          {myopen ? (
            <FontAwesomeIcon icon={faAngleDown} />
          ) : (
            <FontAwesomeIcon icon={faAngleRight} />
          )}
        </Button>
      ) : (
        ""
      )}

      {shorten(content)}
    </div>
    </div>
  );
}
