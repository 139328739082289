import {useState, useEffect, useRef} from "react";
import "./CRM.css";
import axios from "../api/axios";
import {faAngleRight, faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
//import { decrement, increment } from '../app/counterSlice'

import EachNote from "./CRMActionNoteEach.js";

import {useSelector, useDispatch} from "react-redux";
import {
  addNewNoteSlice,
  incremenNoteByAmount,
  toggleNoteOpen
} from "../app/noteSlice";

export default function CrmActionNote(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myToken = useSelector(state => state.identity.token);
  const amIopen = useSelector(state => state.notes.open);
  const allnotes = useSelector(state => state.notes);



const [notes, setNotes] = useState(props.notes);
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [myZ, setmyZ] = useState(10);

  const CASE_NOTE_URL = "/case_note";

  const parse_date = d => {
    return d.substring(0, 10);
  };
  const openme = () => {

    console.log("opening or closing");
    console.log(props.case_id)


    async function fetchData(id) {
          let response = await fetch(`${process.env.REACT_APP_API_BASEURL}/case_note/?c=${id}`,{
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + sessionStorage.sessiontoken
            }});
          response = await response.json();
          console.log(response);
          if (response.length>0 ) dispatch(addNewNoteSlice(notes));
        }


//fetchMedium(props.case_id)

    //setNotes(props.notes)


if (notes.length>0) dispatch(addNewNoteSlice(notes));


    sessionStorage.setItem(
      "noteZindex",
      parseInt(sessionStorage.noteZindex) + 1
    );
    setmyZ(parseInt(sessionStorage.noteZindex));

/*
    if (!loading) {
      setOpen(!open);
      dispatch(toggleNoteOpen(true));
    }

*/
    //if (notes) setOpen(!open);
    //if (!open) dispatch(toggleNoteOpen(true))
    setOpen(!open);
    props.test_state(!open)

    dispatch(toggleNoteOpen(props.case_id));


  };

/*
  const updateActionNotes = (e, id, dd) => {
    if (id == -1) console.log("new note");
    else console.log("update old note");
    var case_id = props.case_id;

    var type = props.open;
    var details = e.target.value;
    var postData = {
      case_id: case_id,
      type: type,
      details: details,
      note_id: id == -1 ? id : dd
    };

    console.log(postData);

    try {
      //
      const UpdateNote = id => {
        axios
          .post(
            CASE_NOTE_URL + "/",

            postData,

            {
              headers: {
                "Content-Type": "application/json",
                //Authorization: "Token " + sessionStorage.mytoken
                Authorization: "Token " + myToken
              }
            }
          )
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
            alert("Something went wrong");
          });
      };
      alert("test");
      var noteId = id == -1 ? id : dd;
      UpdateNote(noteId);
      //window.location.reload(false);
      //  navigate('/crm')
    } catch (err) {
      console.log("error");
    }
  };

  */


  useEffect((notes) => {

    if (props.open) {
console.log('opening?')
      setOpen(true);
      setmyZ(parseInt(sessionStorage.noteZindex));
      sessionStorage.setItem(
        "noteZindex",
        parseInt(sessionStorage.noteZindex) + 1
      );


      //var newnotes = notes.push({type:props.open})
      //setNotes(newnotes);
    }
  }, [notes]);

  return (
    <div className="action-note" style={{zIndex: myZ}}>
      <div>
        {open && amIopen == props.case_id ? (
          <div>
            <span onClick={openme}>
              <Button>
                <FontAwesomeIcon icon={faAngleDown} />
              </Button>
            </span>

            {/*
            <div className="note">
              <h6>[Telecommunication]</h6>
              <textarea
                placeholder="Describe action"
                rows="4"
                cols="50"
                onChange = {updateActionNotes}
              ></textarea>
            </div>

*/}

            {allnotes ? (
              allnotes.data.map(d => (
                <EachNote key={d.pk} data={d} case_id={props.case_id} oldnotes={notes}/>
              ))
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <span onClick={openme}>
            <Button>
              <FontAwesomeIcon icon={faAngleRight} />
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}
