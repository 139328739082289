import {useState, useEffect} from "react";
import "../CRM.css";
import EventDetail from "../EventDetail";
import TopBar from "../TopBar";
import Button from "react-bootstrap/Button";
import SideNav from "../SideNav";
import Form from "react-bootstrap/Form";
import MultiRangeSlider from "multi-range-slider-react";
import "../range.css";
import Dropbox from "../../containers/dropbox";

import {useSelector, useDispatch} from "react-redux";

import {change_from, change_to} from "../../app/userSlice";

function DateRange(props) {
  const dispatch = useDispatch();
  const [first, setFirst] = useState("");
  const [newstart_date, set_newstartdate] = useState("ALL DATES");
  const [newend_date, set_newenddate] = useState("");

  if (props.data) {
    let sortedDate = props.data.sort((a, b) => {
      a = a.survey_date;
      b = b.survey_date;

      return new Date(a) - new Date(b);
    });

    var firstdate = sortedDate[0].survey_date;
    let len = sortedDate.length;
    let lastdate = sortedDate[len - 1].survey_date;

    const monthDiff = (d1, d2) => {
      var months;
      months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
      months -= new Date(d1).getMonth();
      months += new Date(d2).getMonth();
      return months <= 0 ? 0 : months;
    };

    var month_diff = monthDiff(firstdate, lastdate);
  }

  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(month_diff ? month_diff : 5);

  const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };

  const reformatdate = date_string => {
    var xx = date_string.split("/");

    return xx[1] + "-" + xx[0];
  };

  const handleInput = e => {
    const d = new Date(firstdate);

    const _mm = e.minValue;
    const _mx = e.maxValue;
    console.log(d);
    console.log(_mm);
    let _startdate = addMonths(d, _mm);
    console.log(_startdate);
    set_newstartdate(
      reformatdate(_startdate.toLocaleDateString().substring(3, 10))
    );
    dispatch(
      change_from(
        reformatdate(_startdate.toLocaleDateString().substring(3, 10))
      )
    );

    //console.log(_mx);
    let _newenddate = addMonths(d, _mx - _mm);

    set_newenddate(
      reformatdate(_newenddate.toLocaleDateString().substring(3, 10))
    );
    dispatch(
      change_to(reformatdate(_newenddate.toLocaleDateString().substring(3, 10)))
    );

    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  return (
    <>
      <MultiRangeSlider
        min={0}
        max={month_diff ? month_diff : 10}
        step={1}
        ruler={false}
        label={false}
        preventWheel={false}
        minValue={minValue}
        maxValue={maxValue}
        onInput={e => {
          handleInput(e);
        }}
      />
      <div className="range-display">
        <div className="l">{newstart_date ? newstart_date : ""}</div>
        <div className="r">{newend_date ? newend_date : ""}</div>
      </div>
    </>
  );
}

export default function ContactReport() {
  const date_range_from = useSelector(state => state.users.data_date_from);
  const date_range_to = useSelector(state => state.users.data_date_to);
  console.log(date_range_from);

  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState(null);
  const [block, setBlock] = useState(false);
  const [toggleblock, set_toggleblock] = useState(false);
  //const [datesteps, set_datesteps] = useState(null);

  const processData = data => {
    const myProfile = JSON.parse(sessionStorage.myProfile);
    const myGRC = myProfile.grc;

    console.log(myGRC);
    //console.log(date_range_from);
    //console.log(date_range_to);
    //console.log("working");
    //console.log(data.length);
    //console.log(data);

    if (myProfile.user_level > 0) {
      data = data.filter(d => {
        return (d.grc = myGRC);
      });
    }

    if (block) {
      console.log(block);
      console.log(toggleblock);
      if (toggleblock == true) {
        data = data.filter(d => {
          return d.block == block;
        });
      } else data = data;
    }

    if (date_range_from && date_range_to) {
      console.log(date_range_from);
      console.log(date_range_to);
      const compare_from = new Date(date_range_from + "-01");
      const compare_to = new Date(date_range_to + "-01");
      console.log(compare_from);

      data = data
        .filter(d => {
          return new Date(d.survey_date) > compare_from;
        })
        .filter(d => {
          return new Date(d.survey_date) <= compare_to;
        });
    }


    function comparesurveydate( a, b ) {
      if ( a.survey_date < b.survey_date ){
        return 1;
      }
      if ( a.survey_date > b.survey_date ){
        return -1;
      }
      return 0;
    }

    data.sort( comparesurveydate );


    return data;
  };
  const filteredBlocks = data => {
    const set = new Set();
    data.forEach((item, i) => {
      set.add(item.block);
    });

    return Array.from(set);
  };

  const handleSetBlock = e => {
    //console.log(block);
    set_toggleblock(!toggleblock);
    setBlock(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      let response = await fetch(
        `${process.env.REACT_APP_API_BASEURL}/analytics2/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.sessiontoken
          }
        }
      );
      const json = await response.json();


      const consolidated_set = new Set()
      // brent you need to figure out how to manipulate the javascript here

{/*


*/}
      var temp_data = json.data.filter(d => {
        return d.family_name != null && d.family_name != 'NA';
      });

      for (const i in temp_data)     consolidated_set.add(temp_data[i])

      var temp_data = json.data.filter(d => {
        return d.mobile_telegram != null && d.mobile_telegram != "";
      });

      for (const i in temp_data)     consolidated_set.add(temp_data[i])

      var temp_data = json.data.filter(d => {
        return d.follow_up != "" && d.follow_up != 'None';
      });

      for (const i in temp_data)     consolidated_set.add(temp_data[i])

      var temp_data = json.data.filter(d => {
        return d.other_contact != null && d.other_contact != "";
      });

      for (const i in temp_data)     consolidated_set.add(temp_data[i])

      //console.log(rawData);
      //console.log(consolidated_set)
      const temp2 = Array.from(consolidated_set)

      console.log(temp2);
      console.log(consolidated_set)
//
function comparesurveydate( a, b ) {
  if ( a.survey_date < b.survey_date ){
    return 1;
  }
  if ( a.survey_date > b.survey_date ){
    return -1;
  }
  return 0;
}

temp2.sort( comparesurveydate );

//
//



      // create several arrays for different attbutes
      // then merge all of these arrays into one set
      //https://stackoverflow.com/questions/50881453/how-to-add-an-array-of-values-to-a-set
      // then output the set as final array
      // then set rawdata on this array!
      //setRawData(temp_data);
      setRawData(temp2);



      setLoading(false);
    };

    fetchData().catch(console.error);
    console.log(date_range_from);
    console.log(date_range_to);
  }, [setLoading, date_range_from, date_range_to]);

  return (
    <div className="crm-container">
      <div className="container">
        <TopBar where="Contact Report" />

        <div className="side-and-content">
          <div>
            <SideNav active="ContactReport" />
          </div>
          <div className="content-box">
            <div className="range-container">
              {loading ? "" : <DateRange data={rawData} />}
            </div>
            <table>
              <tbody>
                <tr> </tr>
                <tr>
                  {" "}
                  <td colSpan="7">
                    <div
                      style={{textAlign: "left", padding: "10px 0px 0px 15px"}}
                    >
                      <i>Choose by clicking on a block number below</i>
                    </div>
                  </td>
                </tr>

                <tr>
                  <th colSpan="7">
                    <div className="blocks-container">
                      {rawData
                        ? filteredBlocks(processData(rawData)).map(d => (
                            <span className="blocks">
                              <Button>
                                <input
                                  readOnly
                                  onClick={e => {
                                    handleSetBlock(e);
                                  }}
                                  value={d}
                                ></input>
                              </Button>
                            </span>
                          ))
                        : ""}
                    </div>
                  </th>
                </tr>
                <tr>
                  <td colSpan="16">
                    <h2>
                      Contact Report for GRC{" "}
                      {JSON.parse(sessionStorage.myProfile).grc}
                    </h2>
                  </td>
                </tr>

                <tr>
                {/*  <th>Survey_Date</th> */}
                  <th className="s">Blocks</th>
                  <th className="s">Floor</th>
                  <th className="s">Unit</th>
                  <th className="s">Race</th>
                  <th className="s">Support Level</th>
                  <th>Follow-up</th>
                  <th>Respondent Name</th>
                  <th>Mobile</th>
                  <th>Other Contact</th>
                  <th>
                    Resident <br />
                    Comment
                  </th>
                  <th>
                    Member <br />
                    Comment
                  </th>

                </tr>

                {rawData
                  ? processData(rawData).map(d => (
                      <tr>
                      {/*  <td>{d.survey_date}</td> */}
                        <td className="s">{d.block} </td>
                        <td className="s">{d.floor}</td>
                        <td className="s"> {d.unit}</td>
                        <td className="s">{d.race} </td>
                        <td className="s"> {d.support}</td>
                        <td>{d.follow_up} </td>
                        <td>{d.family_name}</td>
                        <td>{d.mobile_telegram}</td>
                        <td>{d.other_contact}</td>
                        <td>{d.suggestion} </td>
                        <td>
                          {" "}
                          <Dropbox content={d.member_comment} />
                        </td>

                      </tr>
                    ))
                  : "loading"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
