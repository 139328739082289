import { useRef, useState, useEffect, useLayoutEffect, useCallback } from "react";
import "./event-table.less";
import axios from "api/axios";
import { connect } from "react-redux";
import DataTable from "./DataTable";


const EVENT_URL = "events";
const PageSize = 5;
const EventTable = ({ appState }) => {

    const { analytic, identity } = appState;


    const [loaded, setLoading] = useState(false);
    const [value, onChange] = useState(new Date());
    const [pageData, setPageData] = useState({

        data: null,
        pageCount: Math.ceil(analytic.data.length / PageSize),
        isLoading: true,

    });



    const addEvent = () => {
        setLoading(true);
    };


    const manageChange = e => {
        console.log("manage change to do");
        console.log(e.target.value);
        setLoading(false);
    };


    useEffect(() => {
        const fetchAnalyticData = async (myToken) => {
            const response = await axios.get(
                EVENT_URL,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Token " + myToken,
                    },
                },
                { withCredentials: true },
            );
            var data = response.data;
            console.log(data)

            setPageData({

                data: data,
                pageCount: Math.ceil(data.length / PageSize),
                isLoading: false,
            });
        }


        if (pageData.isLoading && identity.token) {
            fetchAnalyticData(identity.token);
        }


    }, [identity, analytic, pageData]);


    return (

        <div className="container">


            <div className="side-and-content">
                <div>

                </div>
                <div className="event-content-box">


                    <div className="name-list">
                        <DataTable

                            data={pageData.data}
                        />

                    </div>
                    <div>






                    </div>


                </div>
            </div>
        </div>

    );
}

function mapStateToProps(state) {
    return {
        appState: state,
    };
}

export default connect(mapStateToProps)(EventTable);

//export default EventTable;