import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	data: [],
	open: false,
};
export const notesSlice = createSlice({
	name: "notes",
	initialState,
	reducers: {
		reset: () => initialState,
		addNewNoteSlice: (state, action) => {
			state.data = action.payload;
		},

		incremenNoteByAmount: (state, action) => {
			console.log("here??");
			console.log(action.payload);
			var temp = state.data;
			temp.unshift(action.payload);
			state.data = temp;
		},
		toggleNoteOpen: (state, action) => {
			state.open = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { reset, addNewNoteSlice, incremenNoteByAmount, toggleNoteOpen } =
	notesSlice.actions;

export default notesSlice.reducer;
