import React, {Component} from "react";
import "./react-vis.css";
import {
  XYPlot,
  LineSeries,
  HorizontalBarSeries,
  RadialChart,
  Borders,
  Treemap
} from "react-vis";

export default class SurveyedDataDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      GroundOpsData: [],
      surveychartdata: [],
      token: sessionStorage.token
    };
  }
  componentDidMount() {
    var url = `${process.env.REACT_APP_API_BASEURL}/groundops/`;
    fetch(url, {
      method: "GET",
      withCredentials: true,
      //credentials: 'include',

      headers: {
        //Authorization: "Token " + sessionStorage.token,
        Authorization: "Token " + this.state.token,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        //console.log(response.json());
        return response.json();
      })
      .then(data => {
        console.log(data);
        this.setState({GroundOpsData: data.data});
        this.processData()
      });
  }

  processData= () => {

    var income = this.state.GroundOpsData.map(d => d.income);
    //const income = ['Low (<$4,000)', 'High (>$20,000)', 'High (>$20,000)', 'Low (<$4,000)', 'UPPER MIDDLE', 'UPPER MIDDLE', 'LOW']

    var counts = {};
    var surveychartdata = [];
var _noindicator =0
    for (var i = 0; i < income.length; i++)
      counts[income[i]] = counts[income[i]] + 1 || 1;
    console.log(counts);
var newCounts = {}
    try {
newCounts['HIGH'] = counts['HIGH']
if (counts['High (>$20,000)']) {newCounts['HIGH'] = newCounts['HIGH'] + counts['High (>$20,000)']}
newCounts['LOW'] = counts['LOW']
if (counts['Low (<$4,000)']) {newCounts['LOW'] = newCounts['LOW'] + counts['Low (<$4,000)']}
newCounts['MEDIUM'] = counts['MEDIUM']

newCounts['UPPER MIDDLE'] = counts['UPPER MIDDLE']

newCounts['NA'] = counts['NA'] + counts['null']

    }
    catch(error) {
      newCounts = counts
    }

    for (const [key, value] of Object.entries(newCounts)) {
      console.log(key, value);
surveychartdata.push({label: key, angle: value});




    }
    //surveychartdata.push({label: 'NA', angle: _noindicator,color:"grey"});
    console.log(surveychartdata)
    this.setState({surveychartdata: surveychartdata})
  }

  render() {


    return (
      <div className="dashboard_container">
        <div>
          <h6>Ground Ops > Surveyed Data</h6>
        </div>
        <div className="chart_container">
          <div className="medium_chart">
            <h4>Income Breakdown</h4>
            <RadialChart
              data={this.state.surveychartdata}
              width={240}
              height={200}
              showLabels={true}
              labelsRadiusMultiplier={0.9}
            />
          </div>
        </div>
      </div>
    );
  }
}
