import Layout from "layouts/Auth";
import React, { useRef, useState, useEffect, useContext } from "react";
// import { Link } from "react-router-dom";
import logo from ".././logo.png";
import { Button, Form, Input, Checkbox } from "antd";
// import { useNavigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import axios from "../api/axios";

// import { addTask } from "../reducers/actions";

import { useDispatch } from "react-redux";
import { change, changeProfile } from "../app/counterSlice";
import { changeUser } from "../app/userSlice";

const LOGIN_URL = "/login";
const USER = "/users";

const Login = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const userRef = useRef();
  const errRef = useRef();

  const [username, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  const [checked, setChecked] = useState(true);
  const handleChangeChecked = (e) => {
    setChecked(e.target.checked);
  };

  const handleLogin = (e) => {
    console.log(e.target.value);
    var keyCode = e.keyCode;
    console.log(keyCode);
    if (keyCode == 13 && checked) handleSubmit(e);
  };

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  useEffect(() => {
    sessionStorage.setItem("noteZindex", 10);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checked) {
      return;
    }

    try {
      console.log(LOGIN_URL);
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
        },
        { withCredentials: true }
      );

      const accessToken = response?.data?.token;
      dispatch(change({ token: accessToken }));

      const roles = response?.data?.roles;


      //console.log(response.data.token);
      //console.log(response.data.profile);
      dispatch(changeProfile({ profile: response.data.profile }));
      sessionStorage.setItem("sessiontoken", response.data.token);
      sessionStorage.setItem(
        "myProfile",
        JSON.stringify(response.data.profile)
      );
      sessionStorage.setItem("user_level", response.data.profile.user_level);
      sessionStorage.setItem("myGRC", response.data.profile.grc);
      // load master data

      const master_data = await axios.get(
        USER,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + accessToken,
          },
        },
        { withCredentials: true }
      );

      sessionStorage.setItem("users", JSON.stringify(master_data.data));
      dispatch(changeUser({ data: master_data.data }));

      sessionStorage.setItem("username", username);

      // setAuth({ username, password, roles, accessToken });
      setUser("");
      setPwd("");
      setSuccess(true);
      console.log(response.data.token);

      //addNewTask(sessionStorage.mytoken)

      //  window.location.href = "/crm"
      const redirectPage = sessionStorage.selectedPageUrl;
      if (redirectPage && redirectPage !== "") {
        navigate(redirectPage);
      } else {
        navigate("/crm");
      }
    } catch (err) {
      console.error(err);
      if (err.response == null) {
        setErrMsg("No Server Response");
      } else {
        if (err.response.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err.response.status === 401) {
          setErrMsg("Unauthorized");
        } else {
          setErrMsg("Login Failed");
        }
      }
    }
  };

  return (
    <>
      {success ? (
        <section>
          <h1>Welcome</h1>
          <br />
        </section>
      ) : (
        // <div className="container login">
        //   <div id="login">
        //     <h3>LEADERS<br />DASHBOARD</h3>

        //     <img src={logo} width="30%" alt={"logo"} />

        //     <br />
        //     <br />
        //     <br />
        //     <form onSubmit={handleSubmit}>
        //       <InputGroup size="lg" className="mb-1">
        //         <InputGroup.Text>Username</InputGroup.Text>
        //         <FormControl
        //           type="text"
        //           id="username"
        //           ref={userRef}
        //           autoComplete="off"
        //           onChange={(e) => setUser(e.target.value)}
        //           value={username}
        //           required
        //         />
        //       </InputGroup>
        //       <InputGroup size="lg" className="mb-1">
        //         <InputGroup.Text>Password</InputGroup.Text>
        //         <FormControl
        //           type="password"
        //           id="password"
        //           onChange={(e) => setPwd(e.target.value)}
        //           value={password}
        //           required
        //           onKeyUp = {e=>{handleLogin(e)}}
        //         />
        //       </InputGroup>
        //       <br />
        //       <Button onClick={handleSubmit} >LOGIN</Button>

        //     </form>
        //   </div>
        // </div>

        <Layout className="follow-up-page">
          <div className="auth-box">
            <img src={logo} alt="logo" width="120" className="logo" />
            <h2 className="title">CDSS 2.0 : Main</h2>
            <div className="tagline">
              <span>For members & volunteers only</span>
            </div>
            {errMsg && (
              <div className="error-message">
                <span>{errMsg}</span>
              </div>
            )}
            <Form onSubmit={handleSubmit}>
              <Form.Item
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={username}
                required
              >
                <Input ref={userRef} placeholder="Username" />
              </Form.Item>
              <Form.Item
                onChange={(e) => setPwd(e.target.value)}
                value={password}
                required
                onKeyUp={(e) => {
                  handleLogin(e);
                }}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                onChange={handleChangeChecked}
                value={checked}
                checked={checked}
              >
                <Checkbox className="term-checkbox" defaultChecked={checked}>
                  {" "}
                  All data, generated from this service, is considered strictly
                  confidential and is not to be disclosed to any third party.
                  Any misuse, copying or transmitting of any data may be subject
                  to prosecution, and/or civil liability according to procedures
                  determined by the Party and/or any applicable laws such as
                  PDPA.
                </Checkbox>
              </Form.Item>

              <Form.Item className="form-actions" onClick={handleSubmit}>
                <Button type="primary" disabled={!checked}>
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Layout>
      )}
    </>
  );
};
/*
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };

     const navigate = useNavigate();
  }
  handleChange = e => {
    console.log(e);
    console.log(e.target.value);
    console.log(e.target.id);
    var obj = {};
    obj[e.target.id] = e.target.value;
    this.setState(obj);
  };


  handleClick = e => {
    console.log(this.state);
    console.log(this.props)

     //let history = useHistory()
     navigate("/index");
    //this.props.history.push("/index");
  };


  render() {

  }
}
*/

// const mapStateToProps = (state) => ({
// 	appState: state,
// });

// const mapDispatchToProps = (dispatch) => ({
// 	addNewTask: (task) => dispatch(addTask(task)),
// });
//export default connect(mapStateToProps, mapDispatchToProps)(Login);
export default Login;
