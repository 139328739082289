import {useState, useEffect, useRef} from "react";
import Button from "react-bootstrap/Button";
import axios from "../api/axios";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector, useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom';
import { addNewNoteSlice, incremenNoteByAmount , toggleNoteOpen} from '../app/noteSlice'

export default function EachNote(props) {


  const myToken = useSelector(state => state.identity.token)
  const user_level = useSelector(state => state.identity.profile.user_level)
  const dispatch = useDispatch()
  const amIopen = useSelector(state => state.notes.open)
  const allnotes =  useSelector(state => state.notes.data)
  const navigate = useNavigate();
  const CASE_NOTE_URL = "/case_note";
  //const [noteDescription, setnoteDescription] = useState("");

  const [noteDescription, setnoteDescription] = useState(props.data?.fields.details.details);
  const [noteLocation, setNoteLocation] = useState(props.data?.fields.details.location);
  const [supportLevel, setsupportLevel] = useState(props.data?.fields.details.support);
  const [blockRep, setblockRep] = useState(props.data?.fields.details.rep);
  const [nextaction, setNextAction] = useState(props.data?.fields.details.next_action || "")
  const [reload, setReload] = useState(false)
  const handleKeyDown = e => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
  };

  const d = props.data;
  const parse_date = d => {
    return d.substring(0, 10);
  };

  const updateActionNotes = (e, pk) => {
console.log("TEST")
    console.log(noteLocation);
    console.log(noteDescription);

    //

    console.log(e);

    console.log(pk);


    var case_id = props.case_id;

    var type = d.fields ? d.fields.details.type : d.type;
    var details = e.target.value;
    var postData = {
      case_id: case_id,
      type: type,
      details: noteDescription,
      location: noteLocation,
      rep: blockRep,
      support: supportLevel,
      note_id: pk,
      next_action:nextaction
    };




    try {
      //
      const UpdateNote = () => {
        axios
          .post(
            CASE_NOTE_URL + "/",

            postData,

            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + sessionStorage.sessiontoken
                //Authorization: "Token " + myToken
              }
            }
          )
          .then(response => {
            console.log('sent!!')
            console.log(response);

//





  //
          })
          .catch(error => {
            console.log(error);
            alert("Something went wrong");
          });
      };

      var noteId = pk;
      UpdateNote();





   //navigate('/crm')

      dispatch(toggleNoteOpen(false));
   window.location.reload();

    } catch (err) {
      console.log("error");
    }

    //
  };

  const manageChangeBlockRep = (e, o) => {
    if (e.target.value == 0) alert("Please select valid option");
    else {
      switch (o) {
        case 1:
          setblockRep(e.target.value);
          break;
        case 2:
          setsupportLevel(e.target.value);
          break;
      }
    }
  };

  const todisable = () => {

   switch (user_level) {
     case(0): return true;
     default: return false
   }

  }

const returnselected = (a,b) => {
  if (a===b) return true
  else return false
}
  useEffect(() => {

    console.log(allnotes)

  });

  return (
    <div className="note">
      <h6>
        <div className="type"> {d.fields ? d.fields.details.type : d.type}</div>
        <div className="date">
          Action date: {d.fields ? parse_date(d.fields.updated_at) : ""}
        </div>
      </h6>
      <input
        placeholder="Location"
        onChange={e => setNoteLocation(e.target.value)}
        value={noteLocation}
      />

      <textarea
        cols="51"
        onChange={e => setnoteDescription(e.target.value)}
        value={noteDescription}
        placeholder="Describe the action"
        onKeyDown={handleKeyDown}
       />

{/*    {d.fields ? d.fields.details.details : d.details}

      </textarea>
      */}


      <select onChange={e => manageChangeBlockRep(e, 1)}>
        <option value="0">--Block Rep?--</option>
        <option selected={returnselected(blockRep,"1")}  value="1">Yes</option>
        <option selected={returnselected(blockRep,"2")} value="2">No</option>
        <option selected={returnselected(blockRep,"3")} value="3">Maybe</option>
      </select>

      <select onChange={e => manageChangeBlockRep(e, 2)}>
        <option value="0">--Support Level--</option>
        <option selected={returnselected(supportLevel,"1")} value="1">Red</option>
        <option selected={returnselected(supportLevel,"2")} value="2">Yellow</option>
        <option selected={returnselected(supportLevel,"3")} value="3">Green</option>
        <option selected={returnselected(supportLevel,"4")} value="4">NA</option>
      </select>

      <input
        placeholder="Next Action"
        onChange={e => setNextAction(e.target.value)}
        value={nextaction}
      />

      <Button disabled={todisable()} onClick={e => updateActionNotes(e, d.pk)}>Save</Button>
    </div>
  );
}
