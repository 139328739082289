import moment from "moment";
import { Table, Popover, Button, Input, Slider, Radio } from "antd";
import { PlusOutlined, EllipsisOutlined } from "@ant-design/icons";
import { useState } from "react";
import NoteCell from "components/NoteCell";

const FollowUpTable = ({
  data,
  blocks,
  dateRanges,
  changeFilter,
  selectedBlock,
}) => {



  if (dateRanges) {
    let sortedDate = dateRanges.sort((a, b) => {
      return new Date(a) - new Date(b);
    });

    var firstdate = sortedDate[0];
    let len = sortedDate.length;
    var lastdate = sortedDate[len - 1];

    const monthDiff = (d1, d2) => {
      var months;
      months = (new Date(d2).getFullYear() - new Date(d1).getFullYear()) * 12;
    
    
      months -= new Date(d1).getMonth() ;
     months += new Date(d2).getMonth();


      //const diffTime = Math.abs((new Date(d2).getFullYear())-(new Date(d1).getFullYear()))
      //return (Math.ceil(diffTime/1000*60*60*24))
      //months -= new Date(d1).getDay();
      //months += new Date(d2).getDay();
      console.log(months)
      return months <= 0 ? 0 : months;
    };

    var month_diff = monthDiff(firstdate, lastdate);
  
   

  }
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(month_diff? month_diff : 5);

  const [fromDate, setFromDate] = useState(firstdate);
  const [toDate, setToDate] = useState(lastdate);
  const [ward, setWard] = useState("All");


 


  const handleChangeDateRange = ([from, to]) => {

    
    const date_from = new Date(firstdate).addMonths(from);
    const date_to = new Date(lastdate).addMonths(to - month_diff);

    //const date_from = new Date(firstdate).addDays(from);
    //const date_to = new Date(lastdate).addDayss(to - month_diff);

    setFromDate(moment(date_from).format("YYYY-MM-DD"));
    setToDate(moment(date_to).format("YYYY-MM-DD"));
    

    //setFromDate(moment(firstdate).format("YYYY-MM-DD"));
    //setToDate(moment(lastdate).format("YYYY-MM-DD"));
    return changeFilter({
      date_from,
      date_to,
    });
  };

  const handleChangeBlock = (val) => {
    const newVal = val === selectedBlock ? null : val;
    if (newVal) {
      var ward = data.find((d) => d.block == newVal).ward;
      setWard(ward);
    } else setWard("All");

    return changeFilter({
      selectedBlock: newVal,
    });
  };

  const renderComment = (comment) => {
    return comment ? (
      <NoteCell type="comment" data={comment} />
    ) : // <Popover
    // 	content={
    // 		<div className="dropdown-content">
    // 			<Button type="primary">Click me!</Button>
    // 		</div>
    // 	}
    // 	trigger="click"
    // >
    // 	<Input.Group compact className="follow-up-notes">
    // 		<Button icon={comment ? null : <EllipsisOutlined />}>
    // 			{comment}
    // 		</Button>

    // 		<Button icon={<PlusOutlined />}></Button>
    // 	</Input.Group>
    // </Popover>
    null;
  };
  const columns = [
    {
      title: "Survey Date",
      align: "center",
      dataIndex: "survey_date",
      render: (val) => (val ? moment(val).format("YYYY-MM-DD") : "-"),
    },
    {
      title: "Skipped?",
      align: "center",
      dataIndex: "skip_survey",
      render: (skip) => (skip ? "Yes" : "No"),
    },
    {
      title: "Ward",
      align: "center",
      dataIndex: "ward",
    },
    {
      title: "Block",
      align: "center",
      dataIndex: "block",
    },
    {
      title: "Floor",
      align: "center",
      dataIndex: "floor",
    },
    {
      title: "Unit",
      align: "center",
      dataIndex: "unit",
    },
    {
      title: "Race",
      align: "center",
      dataIndex: "race",
    },
    {
      title: "No of Adults",
      align: "center",
      dataIndex: "no_of_adults",
    },
    {
      title: "SupportL evel",
      align: "center",
      dataIndex: "support",
    },

    {
      title: "Follow Up?",
      align: "center",
      dataIndex: "follow_up",
    },
    {
      title: "Respondent Name",
      align: "center",
      dataIndex: "family_name",
    },
    {
      title: "Mobile/Other Contact",
      align: "center",
      render: (item) => item.mobile_telegram || item.other_contact,
    },
    {
      title: "Resident Comment",
      align: "center",
      dataIndex: "suggestion",
      render: renderComment,
    },
    {
      title: "Member Comment",
      align: "center",
      dataIndex: "member_comment",
      render: renderComment,
    },
    {
      title: "Estate/Family Issues",
      align: "center",
      dataIndex: "problem_concern_family",
      render: renderComment,
    },
    {
      title: "Survey Q1",
      align: "center",
      dataIndex: "q_1",
      render: renderComment,
    },
    {
      title: "Survey Q2",
      align: "center",
      dataIndex: "q_2",
      render: renderComment,
    },
    {
      title: "Request For",
      align: "center",
      dataIndex: "resident_request_for",
    },
    {
      title: "Submitted By",
      align: "center",
      dataIndex: "submitted_by",
    },
  ];

  const renderBlocks = () => {
    const set = new Set();
    data.forEach((item, i) => {
      set.add(item.block);
    });
    var filteredBlocks = Array.from(set);
    console.log(filteredBlocks);
    return filteredBlocks.sort().map((b) => (
      //return blocks.map((b) => (
      <Radio.Button
        checked={selectedBlock}
        onClick={() => handleChangeBlock(b)}
        value={b}
      >
        {b}
      </Radio.Button>
    ));
  };
  return (
    data && (
      <>
        <div className="filter-section">
          <h3>All Dates</h3>
          <div className="filter-box-wrapper">
            <div className="filter-box slider">
       
              <Slider
                range
                min={minValue}
                max={month_diff}
                defaultValue={[minValue, month_diff?month_diff:100]}
                tooltip={{open:false}}
           
                onChange={handleChangeDateRange}
              />
             
            </div>
            <div className="slider-range-value">
              <span>{fromDate?fromDate:firstdate}</span>
              <span>{toDate?toDate:lastdate}</span>
            </div>
          </div>
          <div className="filter-box radio">
            <h4>Choose by clicking on a block number below</h4>
            <Radio.Group value={selectedBlock} buttonStyle="solid">
              {renderBlocks()}
            </Radio.Group>
          </div>
        </div>
        {/*
				<h4>Detailed Report for GRC {JSON.parse(sessionStorage.myProfile).grc}</h4>
				*/}
        <h4>
          Detailed Report for GRC{" "}
          {JSON.parse(sessionStorage.myProfile).user_level === 0
            ? "All"
            : JSON.parse(sessionStorage.myProfile).grc}
        </h4>

        <h4>Ward/District - {ward}</h4>
        <Table dataSource={data} columns={columns} scroll={{ x: "100%" }} />
      </>
    )
  );
};

export default FollowUpTable;
