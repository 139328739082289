import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	data_date_from: "",
	data_date_to: "",

	data: [],
};
export const userSlice = createSlice({
	name: "users",
	initialState,
	reducers: {
		reset: () => initialState,
		changeUser: (state, action) => {
			state.data = action.payload.data;
		},

		incrementUserByAmount: (state, action) => {
			state.data += action.payload;
		},
		change_to: (state, action) => {
			state.data_date_to = action.payload;
		},
		change_from: (state, action) => {
			state.data_date_from = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	reset,
	change_to,
	change_from,
	changeUser,
	incrementUserByAmount,
} = userSlice.actions;

export default userSlice.reducer;
