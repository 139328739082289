import React, { Component } from "react";
import { useState, useEffect } from "react";

import "./CRM.css";
import CrmActionPanel from "./CrmActionPanel";
import CrmActionNote from "./CrmActionNote";
import Dropbox from "../containers/dropbox"
import axios from "../api/axios";
import {connect} from "react-redux";


const CASE_URL = "/case"
const parse_date = d => {
  return d.substring(0, 10);
};
export class CrmCase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotes: false,
      newNote: false,
      //value: { label: this.props.data.handler, value: 1 },
      value: null,
      userDatas:props.currentUsers,
      OptionValues_IC:[],
      appState:props.appState,
      disable_allow_action:true,
    };

//console.log(props)
//console.log(this.state.userDatas)

  }

//userDatas = sessionStorage?.users ? JSON.parse(sessionStorage.users) : [];

//userDatas = this.userDatas.map(d=>{return d})

//userDatas = this.state.userDatas

//  OptionValues_IC = this.userDatas.map(({ username, id }) => ({ "value": id, "label": username }));


  activateNotes = state => {
console.log("Open up notes panel")
    this.setState({ showNotes: true });
    console.log(this.state.showNotes)
  };

  addNote = (v) => {
    console.log('adding note')
    console.log(v)


  //  this.setState({ newNote: v });



 }

 todisable = () => {

  const user_level = JSON.parse(sessionStorage.myProfile).user_level;
  switch (user_level) {
    case(0): return true;
    default: return false
  }

 }



  componentDidMount() {
    try {
    if (this.props.data.action_details.length >0) this.setState({ showNotes: true })
    else this.setState({ disable_allow_action: false })

  //  console.log(this.state.userDatas)

  //  var options = this.state.userDatas.map(({ username, id }) => ({ "value": id, "label": username }));

//console.log(options)

    //this.setState({ userDatas:options })
    //console.log('finsihed?')
    //console.log(this.state.userDatas)

}
catch (err) {
  console.log("no notes")
}


  }

test_state = (state) => {
  console.log('teststate')
  console.log(state)
  this.setState({ disable_allow_action: !state })
}

  handleChange = async (e) => {
    console.log(e)
    this.setState({ value: e.value })



    // Update to server
    try {
      await axios.post(
        CASE_URL + "/" + this.props.data.id,

          JSON.stringify(
            {
            "handler": e['value']
          }
          //postdata
        ),
        {
            headers: {
            'Content-Type': 'application/json',
            "Authorization": "Token " + sessionStorage.mytoken }
          },

        //},
        { withCredentials: true  }
      );
    } catch (error) {
      console.log("Error???");
      this.setState({ error, isLoading: false });
    }


 console.log(this.state.value)
  }



  render() {
    const OptionValues_action = [
          {value:0,label:"Phone Call"},
      {value:1,label:"Messaging"},
      {value:2,label:"House Visit"},
        {value:3,label:"Meetup"},
          {value:4,label:"Others"}
    ];
    const OptionValues_status = [
      {value:0,label:"New"},
      {value:1,label:"Progress"},
      {value:2,label:"Pending"},
      {value:3,label:"Complete"}
    ]


    return (
      <tr>
        <td>{this.props.data.id}</td>
        <td>{parse_date(this.props.data.created_at)}</td>
        <td>
<Dropbox content= {this.props.data.details?.comment}/>
        </td>
        <td>{this.props.data.details?.ward}</td>
        <td>{this.props.data.block}</td>
        <td>{this.props.data.floor}</td>
        <td>{this.props.data.unit}</td>
        <td>{this.props.data.details?.mobile}</td>

{JSON.parse(sessionStorage.myProfile).user_level==2?"":

        <td className='td-text-align-right'>

          <CrmActionPanel
            type={1}
            value={this.props.data.action}
            options={this.state.userDatas.map(({ username, id }) => ({ "value": id, "label": username }))}
            handleChange={this.handleChange}
            case_id={this.props.data.id}
            data={this.props.data}
            disabled={this.todisable()}

          />
        </td>

}

        <td className='td-text-align-right'>

        <CrmActionPanel
          type={3}
          value={this.props.data.status}
          options={OptionValues_status}


          handleChange={this.handleChange}
          case_id={this.props.data.id}
          data={this.props.data}
              disabled={this.todisable()}

        />


        </td>

        {JSON.parse(sessionStorage.myProfile).user_level=='0' || this.props.data.handler == null?
          '':
          <td>
          <CrmActionPanel
            type={2}
            value={this.props.data.action}
            options={OptionValues_action}
            activateNotes={this.activateNotes}
            addNote={this.addNote}
            notelength = {this.props.data.action_details.length}
            //case_id = {this.props.data.id}
            disabled={this.state.disable_allow_action || this.todisable()}


          />
          </td>
        }

        <td className="td-action-note">{this.state.showNotes ? <CrmActionNote key={this.props.data.id} case_id={this.props.data.id} open={true} notes={this.props.data.action_details} test_state = {this.test_state} /> : ""}</td>
      </tr>
    );
  }
}


function mapStateToProps(state) {

  return {
    appState: state
  };
}


export default connect(mapStateToProps)(CrmCase);
