import {useState, useEffect} from "react";

import "../CRM.css";
import "./charts.css";

import "../react-vis.css";
import {RadialChart} from "react-vis";

export default function ChartBrent(props) {
  //console.log(props);
  const fakedata = [
    {label: 1, angle: 1},
    {label: 2, angle: 2}
  ];
  //  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [supportChartData, set_supportChartData] = useState(fakedata);
  //const [rawData, setRawData] = useState(props.data);
  //const [filteredData, setFilteredData] = useState(props.data);

  const handleFilter = e => {
    if (e.target.value == "--ALL--") {
      setFilteredData(rawData);
    } else {
      var filteringData = rawData.filter(d => {
        return d.grc == e.target.value;
      });
      console.log(filteringData)
      setFilteredData(filteringData);
    }
  };


const processChart = (_data) => {


    var support = _data.map(d => d.support);
    var counts = {};

    for (var i = 0; i < support.length; i++)
      counts[support[i]] = counts[support[i]] + 1 || 1;


  var chartdata = [];
  var _noindicator = 0
  var _key
  const color_dict ={"YELLOW":"yellow", "RED":"red","GREEN":"green"}
  for (const [key, value] of Object.entries(counts)) {
    console.log(key, value);
    if (key == "NA" || key == "NONE" || key == 'null') {
      _noindicator += value
    }
    else {
      _key = key
    chartdata.push({label: _key, angle: value, color:color_dict[_key]});}

  }
  chartdata.push({label: 'NA', angle: _noindicator,color:"grey"});

return chartdata


}
  useEffect(() => {
    setRawData(props.data);
    setFilteredData(props.data);

//work on chart


//set_supportChartData (chartdata)

}, [props]);

  return (
    <div className="chartarea">
      <h5>Support Level</h5>
      <select
        name="x"
        onChange={e => {
          handleFilter(e);
        }}
      >
        <option>--ALL--</option>
        {props.options
          ? props.options.map(d => <option value={d.value}>{d.label}</option>)
          : ""}
      </select>

    {/*
      <p>[Date Range] </p>
*/}

{filteredData?
      <RadialChart
        data={processChart(filteredData)}
        width={500}
        height={400}
        showLabels={true}
        showLabels={true}
         colorType="literal"
      />
      :""}

    </div>
  );
}
