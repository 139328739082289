import {useState, useEffect} from "react";
import "../CRM.css";
import "./charts.css";

export default function ChartBrent(props) {
  const [rawData, set_rawData] = useState([]);
    const [filterDate, set_filterDate] = useState('');



  const summarize_data =(data) => {

    //console.log(props.data)
    var set_block = new Set();
    var skipped = 0
    var follow_up = 0
    var support_green = 0
    var support_green_percent = 0
    var support_yellow = 0
    var support_yellow_percent = 0
    var support_red = 0
    var support_red_percent = 0
    var mobile_number = 0
    var name_resp =0

    for (var i = 0; i < data.length; i++) {
      set_block.add(props.data[i].block);
      if (props.data[i].skip_survey == true) skipped += 1
      if (props.data[i].follow_up == "Yes") follow_up += 1
      if (props.data[i].support == "GREEN") support_green += 1
      if (props.data[i].support == "YELLOW") support_yellow += 1
      if (props.data[i].support == "RED") support_red += 1
      if (props.data[i].mobile_telegram == true) mobile_number += 1
      if (props.data[i].family_name != "" && props.data[i].family_name != "NA" && props.data[i].family_name != null) name_resp += 1

    }

    return {
      blocks: set_block.size,
      total:data.length,
      skipped: skipped,
      follow_up: follow_up,
      support_green: support_green,
      support_green_percent: support_green/data.length*100,
      support_yellow: support_yellow,
      support_yellow_percent: support_yellow/data.length*100,
      support_red: support_red,
      support_red_percent: support_red/data.length*100,
      mobile_number: mobile_number,
      name_resp: name_resp
        };


  };


  const handleFilter = e => {

    if (e.target.value == "--ALL--") {
      set_rawData(props.data);
    }
    else {
    var filteringData = props.data.filter(d => {
      return d.grc == e.target.value;
    });
    console.log(filteringData);
    set_rawData(filteringData);
  }
  };

const handleFilterDate =(e) => {


  var keyCode = e.keyCode;
    console.log(keyCode)
    if (keyCode==13) {

      try {

        var _temp = props.data.filter(d=>{return d.updated_at.substring(0, 10)==filterDate})
        set_rawData(_temp);


      }
      catch (error) {
        console.log('error')
      }

    }
}


  useEffect(() => {
set_rawData(props.data)
  //  set_summaryData(summarize_data(props.data));

//work on chart


//set_supportChartData (chartdata)

}, [props]);


  return (
    <div>
      <h3>Summary Statistics</h3>
      <select
        name="x"
        onChange={e => {
          handleFilter(e);
        }}
      >
        <option>--ALL--</option>
        {props.options
          ? props.options.map(d => <option value={d.value}>{d.label}</option>)
          : ""}
      </select>
      <br />
      <label>Filter Date</label>
      <input className="analytics"
          onKeyUp = {e=>{handleFilterDate(e)}}
      onChange={e=>{set_filterDate(e.target.value)}}
      placeholder = "yyyy-mm-dd"
      />
      <br />
      {props.data ? (
        <table className="summary">
          <tbody>
            <tr>
              <td>Total Blocks Covered</td>
              <td>{rawData?summarize_data(rawData).blocks:''}</td>
            </tr>
            <tr>
              <td>Total Number of Surveys</td>
              <td>{rawData?summarize_data(rawData).total:''}</td>
            </tr>
            <tr>
              <td>Number of Skipped Surveys Units:</td>
              <td>{rawData?summarize_data(rawData).skipped:''}</td>
            </tr>
            <tr>
              <td>Number of Full Surveys</td>
              <td>{rawData?summarize_data(rawData).total-summarize_data(rawData).skipped:''}</td>
            </tr>
            <tr>
              <td>Follow-up Unit</td>
              <td>{rawData?summarize_data(rawData).follow_up:''}</td>
            </tr>
            <tr>
              <td>Support Level (Full Survey - GREEN only)</td>
              <td>{rawData?summarize_data(rawData).support_green:''}</td>
              <td>,</td>
              <td>{rawData?summarize_data(rawData).support_green_percent.toFixed(2):''}</td><td>%</td>
            </tr>
            <tr>
              <td>Support Level (Full Survey - YELLOW only)</td>
              <td>{rawData?summarize_data(rawData).support_yellow:''}</td>
              <td>,</td>
              <td>{rawData?summarize_data(rawData).support_yellow_percent.toFixed(2):''}</td><td>%</td>
            </tr>
            <tr>
              <td>Support Level (Full Survey - RED only)</td>
              <td>{rawData?summarize_data(rawData).support_red:''}</td>
              <td>,</td>
              <td>{rawData?summarize_data(rawData).support_red_percent.toFixed(2):''}</td><td>%</td>
            </tr>
            <tr>
              <td>Mobile Numbers Obtained</td>
              <td>{rawData?summarize_data(rawData).mobile_number:''}</td>
            </tr>
            <tr>
              <td>Names Obtained</td>
              <td>{rawData?summarize_data(rawData).name_resp:''}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        ""
      )}
    </div>
  );
}
