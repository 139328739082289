import { Table, Popover, Button, Input, Slider, Radio } from "antd";
import React, { useContext, useEffect, useRef, useState } from 'react';
const DataTable = ({ data }) => {

    const [cameraScreen, setCameraScreen] = useState(false);
    const [camera, setCamera] = useState(false);
    const [eventID, setEventID] = useState(null);
    const handleShow = (key) => {

        alert(JSON.stringify(key))
    };

    const activateCameraScreen = (key) => {

        setEventID(key)
        setCameraScreen(true)
    };

    const activateCamera = () => {
        setCamera(true)
    }


    const columns = [
        {
            title: "Event Date",
            align: "center",
            dataIndex: "event_date",

        },
        {
            title: "Details",
            key: 'action',
            render: (record) => (
                <Button size='small' onClick={() => handleShow(record.details)}>Show?</Button>
            )
        },
        {
            title: "Select",
            key: 'action',
            render: (record) => (
                <Button type="primary"  size='small' onClick={() => activateCameraScreen(record.id)}>Select</Button>
            )
        }
    ]


    const CameraScreen = ({ id }) => {
        return (
            <>
                <p>Record participants for event id {id}:
                </p>
                {camera?
                
                <p>C</p>
                :
                
                <Button onClick={() => activateCamera()} type="primary">Scan Participant's QR code</Button>
                }
                <br />
                <br />
                <Button onClick={() => {setCameraScreen(false);setCamera(false)}}>Choose another event</Button>
            </>
        )
    }

    return (

        data && (
            <>
                {cameraScreen ?
                    <CameraScreen id={eventID} /> :
                    <Table dataSource={data} columns={columns} scroll={{ x: "100%" }} />


                }
            </>
        )

    )


}

export default DataTable;