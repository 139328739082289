import { routes } from "routes";
import { Link } from "react-router-dom";
import Layout from "layouts";
import mobileSurvey from "./media/mobile-survey.svg";
import followUp from "./media/follow-up.svg";
import dataAnalytics from "./media/data-analytics.svg";
import eventIcon from "./media/dashboard.svg";

import "./mobile-menu.less";
const MainMenuPage = () => {
  const menuItems = [
    {
      key: "mobile-survey",
      icon: mobileSurvey,
      label: "Mobile Survey",
      path: process.env.REACT_APP_CDSS_URL,
    },
    {
      key: "follow-up",
      icon: followUp,
      label: "Follow-up Cases",
      path: routes.followUp,
    },
    {
      key: "data-analytics",
      icon: dataAnalytics,
      label: "Dashboard & Analytics",
      path: routes.overview,
    },
    {
      key: "dashboard",
      icon: eventIcon,
      label: "Event & Report",
      path: routes.surveyReport,
    },
  ];
  const listItems = menuItems.map((item) => <MainMenuItem item={item} />);
  return (
    <Layout className="mobile-menu-page" hideMenu>
      <div className="menu-box">
        <h2 className="title">
          CDSS <br />
          Main Menu
        </h2>
        <div className="menu-items">{listItems}</div>
        <h3 className="tagline">Please select an option</h3>
      </div>
    </Layout>
  );
};

const MainMenuItem = ({ item }) => {
  const className = `item ` + item.key;

  const path = item.key === "mobile-survey" ? item.path : routes.login;
  const setPage = () => {
    sessionStorage.setItem("selectedPageUrl", item.path);
  };

  const renderContentMainMenuItem = (item) => {
    return (
      <div className={className}>
        <div className="item-content">
          <div className="icon">
            <img src={item.icon} alt={item.label} />
          </div>
          <h3>{item.label}</h3>
        </div>
      </div>
    );
  };

  return item.key !== "mobile-survey" ? (
    <Link to={path} onClick={setPage}>
      {renderContentMainMenuItem(item)}
    </Link>
  ) : (
    <a href={path}>{renderContentMainMenuItem(item)}</a>
  );
};

export default MainMenuPage;
