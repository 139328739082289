import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import "./CRM.css";

import { faUser} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useSelector, useDispatch} from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TopBar(props) {
console.log(sessionStorage.myProfile)
//var grc = useSelector(state => state.identity.profile.grc)
var grc = JSON.parse(sessionStorage.myProfile).grc
const user_level = JSON.parse(sessionStorage.myProfile).user_level
if (user_level == 0) grc = "HQ"
console.log(grc)
const username = sessionStorage.username

  return <div className="top-bar">

<h2>Dashboard > {grc}: {props.where} </h2>
<div className="person">
<div>
<FontAwesomeIcon icon={faUser} />
</div>
<div>&nbsp;&nbsp; {username} &nbsp;&nbsp;</div>
<div>        <Link to="/"><FontAwesomeIcon icon={faArrowRight} /></Link></div>
</div>



  </div>;
}
