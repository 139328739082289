import React, {Component} from "react";
//import { Link } from 'react-router-dom'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import map from ".././map.png";
//https://uber.github.io/react-vis/documentation/general-principles/style
//radarr 12 mtns - $2k per month prerna.pant@radarr.com
//     width={140}
//    height={120}

import "./react-vis.css";
import {
  XYPlot,
  LineSeries,
  RadialChart,
  Borders,
  Treemap,
  LabelSeries,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  XAxis,
  YAxis
} from "react-vis";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);


    this.state = {
      useCanvas: false,
      member_data: [
        [1, "Malay", 50, 640623.0],
        [2, "Chinese", 62, 753363.0],
        [3, "Chinese", 29, 510564.0],
        [4, "Malay", 68, 797478.0],
        [5, "Malay", 66, 258541.0],
        [6, "Malay", 32, 760803.0],
        [7, "Malay", 34, 470132.0],
        [8, "Malay", 38, 534194.0],
        [9, "Malay", 37, 332010.0],
        [10, "Malay", 37, 730707.0],
        [11, "Malay", 55, 600374.0],
        [12, "Malay", 47, 530246.0],
        [13, "Malay", 38, 370037.0],
        [14, "Malay", 61, 650246.0],
        [15, "Malay", 52, 460109.0],
        [16, "Malay", 36, 310200.0],
        [17, "Malay", 59, 730339.0],
        [18, "Malay", 33, 760771.0],
        [19, "Malay", 32, 198896.0],
        [20, "Malay", 69, 543307.0],
        [21, "Malay", 29, 510780.0],
        [22, "Chinese", 35, 760457.0],
        [23, "Malay", 27, 760132.0],
        [24, "Chinese", 50, 600210.0],
        [25, "Chinese", 47, 530252.0],
        [26, "Chinese", 68, 643698.0],
        [27, "Chinese", 52, 760634.0],
        [28, "Chinese", 52, 529892.0],
        [29, "Chinese", 43, 791446.0],
        [30, "Chinese", 34, 680454.0],
        [31, "Chinese", 37, 120706.0],
        [32, "Chinese", 57, 640915.0],
        [33, "Chinese", 45, 730025.0],
        [34, "Chinese", 39, 730893.0],
        [35, "Chinese", 41, 545044.0],
        [36, "Chinese", 51, 791446.0],
        [37, "Chinese", 27, 760132.0],
        [38, "Chinese", 38, 545044.0],
        [39, "Chinese", 51, 560181.0],
        [40, "Chinese", 38, 520342.0],
        [41, "Chinese", 21, 760354.0],
        [42, "Chinese", 69, 520276.0],
        [43, "Chinese", 42, 127649.0],
        [44, "Chinese", 48, 528781.0],
        [45, "Chinese", 57, 133028.0],
        [46, "Chinese", 30, 768011.0],
        [47, "Chinese", 37, 670480.0],
        [48, "Chinese", 54, 390060.0],
        [49, "Chinese", 49, 643517.0],
        [50, "Chinese", 57, 679520.0],
        [51, "Chinese", 71, 537926.0],
        [52, "Chinese", 60, 120609.0],
        [53, "Chinese", 58, 562700.0],
        [54, "Chinese", 27, 680677.0],
        [55, "Chinese", 74, 259811.0],
        [56, "Chinese", 76, 228519.0],
        [57, "Chinese", 61, 310036.0],
        [58, "Chinese", 42, 670469.0],
        [59, "Chinese", 58, 142050.0],
        [60, "Chinese", 43, 138638.0],
        [61, "Indian", 48, 119461.0],
        [62, "Eurasian", 42, 806105.0],
        [63, "Chinese", 53, 520488.0],
        [64, "Chinese", 57, 689874.0],
        [65, "Chinese", 43, 142052.0],
        [66, "Chinese", 63, 127466.0],
        [67, "Chinese", 0, 258591.0],
        [68, "Chinese", 71, 310111.0],
        [69, "Chinese", 32, 611164.0],
        [70, "Indian", 36, 120412.0],
        [71, "Chinese", 36, 730174.0],
        [72, "Eurasian", 75, 279564.0],
        [73, "Chinese", 71, 670609.0],
        [74, "Chinese", 47, 752115.0],
        [75, "Chinese", 72, 369841.0],
        [76, "Chinese", 27, 640725.0],
        [77, "Chinese", 72, 560351.0],
        [78, "Chinese", 69, 570254.0],
        [79, "Chinese", 68, 540236.0],
        [80, "Chinese", 71, 277089.0],
        [81, "Malay", 49, 680290.0],
        [82, "Chinese", 28, 357741.0],
        [83, "Chinese", 70, 598744.0],
        [84, "Chinese", 33, 560585.0],
        [85, "Chinese", 51, 310155.0],
        [86, "Chinese", 58, 650111.0],
        [87, "Chinese", 25, 570214.0],
        [88, "Chinese", 24, 287083.0],
        [89, "Chinese", 47, 596195.0],
        [90, "Chinese", 78, 574688.0],
        [91, "Chinese", 67, 570125.0],
        [92, "Chinese", 43, 680296.0],
        [93, "Chinese", 51, 820297.0],
        [94, "Chinese", 58, 426955.0],
        [95, "Chinese", 47, 160139.0],
        [96, "Chinese", 46, 670469.0],
        [97, "Chinese", 61, 822160.0],
        [98, "Chinese", 48, 560121.0],
        [99, "Chinese", 72, 390058.0],
        [100, "Chinese", 51, 652453.0],
        [101, "Chinese", 51, 563588.0],
        [102, "Chinese", 33, 100085.0],
        [103, "Chinese", 78, 557735.0],
        [104, "Chinese", 61, 140154.0],
        [105, "Chinese", 61, 680444.0],
        [106, "Chinese", 46, 793443.0],
        [107, "Chinese", 20, 127651.0],
        [108, "Chinese", 54, 600247.0],
        [109, "Chinese", 38, 682817.0],
        [110, "Chinese", 64, 530156.0],
        [111, "Chinese", 33, 760459.0],
        [112, "Chinese", 24, 752106.0],
        [113, "Chinese", 56, 541119.0],
        [114, "Chinese", 59, 510270.0],
        [115, "Chinese", 62, 330057.0],
        [116, "Chinese", 31, 680218.0],
        [117, "Chinese", 72, 371082.0],
        [118, "Chinese", 43, 510559.0],
        [119, "Chinese", 46, 549526.0],
        [120, "Chinese", 48, 437431.0],
        [121, "Indian", 85, 287295.0],
        [122, "Chinese", 68, 650348.0],
        [123, "Chinese", 48, 80011.0],
        [124, "Chinese", 49, 600327.0],
        [125, "Chinese", 64, 426481.0],
        [126, "Malay", 38, 141092.0],
        [127, "Malay", 70, 824176.0],
        [128, "Chinese", 43, 521247.0],
        [129, "Malay", 61, 461052.0],
        [130, "Chinese", 40, 823128.0],
        [131, "Chinese", 59, 312080.0],
        [132, "Chinese", 42, 520876.0],
        [133, "Chinese", 59, 760108.0],
        [134, "Chinese", 27, 640425.0],
        [135, "Chinese", 66, 643682.0],
        [136, "Chinese", 61, 440033.0],
        [137, "Chinese", 40, 567766.0],
        [138, "Chinese", 45, 682688.0],
        [139, "Chinese", 74, 680759.0],
        [140, "Chinese", 42, 534199.0],
        [141, "Chinese", 46, 575583.0],
        [142, "Chinese", 70, 268428.0],
        [143, "Chinese", 0, null],
        [144, "Chinese", 55, 600063.0],
        [145, "Chinese", 70, 822211.0],
        [146, "Chinese", 33, 143062.0],
        [147, "Chinese", 42, 600060.0],
        [148, "Chinese", 59, 163002.0],
        [149, "Chinese", 68, 670241.0],
        [150, "Chinese", 43, 600041.0],
        [151, "Chinese", 34, 509489.0],
        [152, "Chinese", 52, 600325.0],
        [153, "Chinese", 57, 119165.0],
        [154, "Chinese", 42, 730515.0],
        [155, "Chinese", 43, 277199.0],
        [156, "Chinese", 32, 128089.0],
        [157, "Chinese", 38, 600041.0],
        [158, "Chinese", 53, 732574.0],
        [159, "Chinese", 68, 824176.0],
        [160, "Chinese", 50, 730115.0],
        [161, "Chinese", 67, 437083.0],
        [162, "Chinese", 63, 141086.0],
        [163, "Chinese", 71, 530541.0],
        [164, "Chinese", 62, 689575.0],
        [165, "Chinese", 46, 540256.0],
        [166, "Chinese", 38, 732572.0],
        [167, "Indian", 54, 529870.0],
        [168, "Eurasian", 56, 479243.0],
        [169, "Chinese", 46, 732687.0],
        [170, "Chinese", 41, 541313.0],
        [171, "Chinese", 49, 129551.0],
        [172, "Chinese", 48, 271013.0],
        [173, "Chinese", 32, 670432.0],
        [174, "Chinese", 31, 520259.0],
        [175, "Chinese", 26, 519931.0],
        [176, "Indian", 48, 760733.0],
        [177, "Chinese", 42, 641561.0],
        [178, "Eurasian", 42, 312009.0],
        [179, "Chinese", 55, 90018.0],
        [180, "Chinese", 61, 570122.0],
        [181, "Chinese", 49, 648381.0],
        [182, "Chinese", 42, 120109.0],
        [183, "Chinese", 44, 640411.0],
        [184, "Chinese", 67, 127312.0],
        [185, "Chinese", 32, 641649.0],
        [186, "Chinese", 65, 520483.0],
        [187, "Malay", 67, 574231.0],
        [188, "Chinese", 63, 730633.0],
        [189, "Chinese", 73, 560603.0],
        [190, "Chinese", 55, 530448.0],
        [191, "Chinese", 57, 598739.0],
        [192, "Chinese", 29, 680443.0],
        [193, "Chinese", 75, 798347.0],
        [194, "Chinese", 18, 670181.0],
        [195, "Chinese", 58, 642181.0],
        [196, "Chinese", 56, 760760.0],
        [197, "Chinese", 55, 504144.0],
        [198, "Chinese", 44, 150088.0],
        [199, "Chinese", 65, 457242.0],
        [200, "Chinese", 51, 180263.0],
        [201, "Chinese", 66, 310254.0],
        [202, "Chinese", 67, 520241.0],
        [203, "Chinese", 38, 426065.0],
        [204, "Chinese", 60, 510475.0],
        [205, "Chinese", 31, 799686.0],
        [206, "Chinese", 63, 596748.0],
        [207, "Chinese", 52, 530965.0],
        [208, "Chinese", 38, 751469.0],
        [209, "Chinese", 40, 380043.0],
        [210, "Chinese", 39, 752120.0],
        [211, "Chinese", 55, 471609.0],
        [212, "Chinese", 48, 738081.0],
        [213, "Chinese", 63, 550235.0],
        [214, "Chinese", 64, 768064.0],
        [215, "Chinese", 45, 600005.0],
        [216, "Chinese", 50, 129961.0],
        [217, "Chinese", 59, 120701.0],
        [218, "Chinese", 63, 288445.0],
        [219, "Chinese", 33, 81008.0],
        [220, "Chinese", 48, 449286.0],
        [221, "Chinese", 37, 461024.0],
        [222, "Chinese", 41, 738209.0],
        [223, "Chinese", 40, 440029.0],
        [224, "Chinese", 52, 239343.0],
        [225, "Chinese", 55, 600021.0],
        [226, "Chinese", 46, 759150.0],
        [227, "Indian", 73, 383008.0],
        [228, "Chinese", 30, 689674.0],
        [229, "Chinese", 36, 320095.0],
        [230, "Chinese", 43, 680638.0],
        [231, "Chinese", 34, 761507.0],
        [232, "Chinese", 73, 161099.0],
        [233, "Chinese", 59, 688400.0],
        [234, "Chinese", 60, 545019.0],
        [235, "Indian", 71, 276976.0],
        [236, "Chinese", 66, 600320.0],
        [237, "Chinese", 47, 683476.0],
        [238, "Chinese", 71, 670132.0],
        [239, "Chinese", 72, 536978.0],
        [240, "Malay", 73, 544298.0]
      ]
    };
  }

  render() {
    const {useCanvas} = this.state;
    const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;
    const content = useCanvas ? "TOGGLE TO SVG" : "TOGGLE TO CANVAS";

    var memberChartData = [];
    console.log(this.state.member_data);

    var age = this.state.member_data.map(d => d[2]);
    var counts = {};
    console.log(age);
    for (var i = 0; i < age.length; i++) {
      if (age[i] < 20) {
        counts["< 20"] = counts["< 20"] + 1 || 1;
      }
      if (age[i] >= 20 && age[i] < 30) {
        counts["20 ~ < 30"] = counts["20 ~ < 30"] + 1 || 1;
      }
      if (age[i] >= 30 && age[i] < 40) {
        counts["30 ~ < 40"] = counts["30 ~ < 40"] + 1 || 1;
      }
      if (age[i] >= 40 && age[i] < 50) {
        counts["40 ~ < 50"] = counts["40 ~ < 50"] + 1 || 1;
      }
      if (age[i] >= 50 && age[i] < 60) {
        counts["50 ~ < 60"] = counts["50 ~ < 60"] + 1 || 1;
      }
      if (age[i] >= 60 && age[i] < 70) {
        counts["60 ~ < 70"] = counts["60 ~ < 70"] + 1 || 1;
      }
      if (age[i] >= 70) {
        counts[">= 70"] = counts[">= 70"] + 1 || 1;
      }
    }
    console.log(counts);
    const color_dict = {
      "< 20": "pink",
      "20 ~ < 30": "#189AB4",
      "30 ~ < 40": "#75E6DA",
      "40 ~ < 50": "#D4F1F4",
      "50 ~ < 60": "#05445E",
      "60 ~ < 70": "#75E6DA",
      ">= 70": "yellow"
    };
    for (const [key, value] of Object.entries(counts)) {
      console.log(key, value);

      memberChartData.push({label: key, angle: value, color: color_dict[key]});
    }

    /*


var counts = {};

for (var i = 0; i < race.length; i++)
  counts[race[i]] = counts[race[i]] + 1 || 1;
  console.log(counts)
  var memberChartData = [];
  const color_dict ={"1":"#05445E", "2":"#189AB4","3":"#75E6DA","4":"#D4F1F4"}
  for (const [key, value] of Object.entries(counts)) {
    console.log(key, value);


      var _key , _color


      switch (key) {
    case (>=40):
        _key = "40"
        _color= olor_dict["3"]
    case (>=30 && < 40):
        _key = "Above 30 below 40"
        _color= olor_dict["2"]
    case ()<30):
        _key = "Below 30"
        _color= olor_dict["1"]




    memberChartData.push({label: _key, angle: value, color:_color})

  }
*/

const mapStyles = {
  width: '100%',
  height: '100%',
};
    const axis_style = {
      line: {stroke: "grey"},
      ticks: {stroke: "grey"},
      text: {stroke: "grey", fill: "grey", fontWeight: 100}
    };

    const tempdata = [
      {label: "Member", angle: 255, color: "#05445E"},
      {label: "Volunteer", angle: 64, color: "#189AB4"}
    ];

    const linechart_data = [
      {x: "Jan", y: 1},
      {x: "Feb", y: 1.5},
      {x: "March", y: 3},
      {x: "April", y: 4},
      {x: "May", y: 6.5},
      {x: "June", y: 13},
      {x: "July", y: 15},
      {x: "Aug", y: 30.5},
      {x: "Sep", y: 44},
      {x: "Oct", y: 44.1},
      {x: "Nov", y: 45},
      {x: "Dec", y: 49}
    ];

    //  data={this.props.chartdata}
    //     data={tempdata}
    return (
      <div className="dashboard_container">
        {this.props.scope ? (
          <div>
            <div className="home">
              <Row className="justify-content-md-center">
                <Col>
                  <h4>Membership - Overview</h4>
                </Col>
              </Row>

              <div className="chart_container">
                <div className="medium_chart small_text">
                  <h4>Age Profile</h4>
                  <RadialChart
                    data={memberChartData}
                    width={240}
                    height={200}
                    showLabels={true}
                    colorType="literal"
                    labelsAboveChildren={false}
                    labelsRadiusMultiplier={0.9}
                  ></RadialChart>
                </div>

                <div className="medium_chart ">
                  <h4>Member/ Volunteer</h4>
                  <RadialChart
                    data={tempdata}
                    width={240}
                    height={200}
                    showLabels={true}
                    colorType="literal"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="home">
              <Row className="justify-content-md-center">
                <Col>
                  <h4>Ground Operations - Overview</h4>
                </Col>
              </Row>
            </div>

            <div className="chart_container">
              <div className="medium_chart">
                <h4>Door to door Coverage</h4>
                <XYPlot xType="ordinal" height={200} width={240}>
                  <HorizontalGridLines style={{stroke: "grey"}} />
                  <XAxis title="Time" style={axis_style} />
                  <YAxis title="%" style={axis_style} />
                  <BarSeries data={linechart_data} />
                </XYPlot>
              </div>

              <div className="medium_chart">
                <h4>Support Level</h4>
                <RadialChart
                  data={this.props.chartdata}
                  width={240}
                  height={200}
                  showLabels={true}
                  showLabels={true}
                  colorType="literal"
                />
              </div>
            </div>
            <div className="map_container">
              <div className="map_chart">

                    <img src={map} width="100%" alt={"map"} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
