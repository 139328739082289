import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	data: [],
	isLoading: true,
};
export const analytic = createSlice({
	name: "analytic",
	initialState,
	reducers: {
		reset: () => initialState,
		setData: (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		},
	},
});

// Action creators are generated for each case reducer function
export const { reset, setData } = analytic.actions;

export default analytic.reducer;
