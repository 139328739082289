import React, {Component} from "react";
//import { Link } from 'react-router-dom'
import "./react-vis.css";
import {
  XYPlot,
  LineSeries,
  HorizontalBarSeries,
  RadialChart,
  Borders,
  Treemap
} from "react-vis";
import Sidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import TopBar from "./TopBar";

//https://uber.github.io/react-vis/documentation/general-principles/style

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      GroundOpsData: [],
      chartdata:[],
      token:sessionStorage.token,
    };
  }

  componentDidMount() {
    console.log(sessionStorage)
    var url = `${process.env.REACT_APP_API_BASEURL}/groundops/`
    fetch(url, {
      method: "GET",
      withCredentials: true,
      //credentials: 'include',

      headers: {
        //Authorization: "Token " + sessionStorage.token,
        "Authorization": "Token " + sessionStorage.token,
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        //console.log(response.json());
        return response.json();
      })
      .then(data => {
        console.log(data)
        this.setState({GroundOpsData: data.data});

        this.processData();
      });
  }

  processData=()=> {


    var support = this.state.GroundOpsData.map(d => d.support);
    var income = this.state.GroundOpsData.map(d => d.income);
    console.log(income);

    var counts = {};

    for (var i = 0; i < support.length; i++)
      counts[support[i]] = counts[support[i]] + 1 || 1;
    var chartdata = [];
    var _noindicator = 0
    var _key
    const color_dict ={"YELLOW":"yellow", "RED":"red","GREEN":"green"}
    for (const [key, value] of Object.entries(counts)) {
      console.log(key, value);
      if (key == "NA" || key == "NONE" || key == 'null') {
        _noindicator += value
      }
      else {
        _key = key
      chartdata.push({label: _key, angle: value, color:color_dict[_key]});}

    }
    chartdata.push({label: 'NA', angle: _noindicator,color:"grey"});
    console.log(chartdata);
    this.setState({chartdata: chartdata});
  }

  render() {

    return (
      <div className="container">
        <div id="nav">
          <TopBar />
        </div>
        <div id="main">
          <Dashboard chartdata={this.state.chartdata} token={this.state.token}/>
        </div>

        <div id="sidebar">
          <Sidebar  />
        </div>
      </div>
    );
  }
}
