import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Layout, Menu, Button, Popover, Drawer } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  IconMenu,
  IconOverview,
  IconSurvey,
  IconFollowUp,
  IconContact,
} from "components/Icons";
import Logo from "./media/logo-icon.svg";
import LogoIcon from "./media/logo-icon-2.png";
import { utils } from "app/utils";

import avatar from "./media/avatar.jpg";

import "./main.less";
import "./layout.less";
import { connect } from "react-redux";
import { change, changeProfile, reset as resetCounter } from "app/counterSlice";
import { changeUser, reset as resetUser } from "app/userSlice";
import { reset as resetCrm } from "app/crm";
import { reset as resetNote } from "app/noteSlice";
import { routes } from "routes";

const { Header, Sider, Content, Footer } = Layout;

Date.isLeapYear = function (year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

Date.getDaysInMonth = function (year, month) {
  return [
    31,
    Date.isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][month];
};

Date.prototype.isLeapYear = function () {
  return Date.isLeapYear(this.getFullYear());
};

Date.prototype.getDaysInMonth = function () {
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};

const DefaultLayout = (props) => {
  const {
    children,
    title,
    showIcon,
    className,
    selectedKey,
    appState,
    change,
    changeProfile,
    changeUser,
    hideMenu,
    resetState,
  } = props;
  let navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const token = sessionStorage.getItem("sessiontoken");

  useEffect(() => {
    if (!token) {
      navigate(utils.isMobile() ? routes.mobileMenu : routes.home);
    } else {
      if (!appState.identity?.token) {
        change({ token });
      }
      const profile = sessionStorage.getItem("myProfile");
      if (profile && _.isEmpty(appState.identity?.profile)) {
        changeProfile({ profile: JSON.parse(profile) });
      }

      const users = sessionStorage.getItem("users");
      if (users && _.isEmpty(appState.users?.data)) {
        changeUser({ data: JSON.parse(users) });
      }
    }
  }, [token, navigate, change, changeProfile, changeUser, appState]);

  const mapRoutes = {
    "follow-up": routes.followUp,
    "survey-report": routes.surveyReport,
    overview: routes.overview,
    "contact-report": routes.contactReport,
    "event": routes.event,
  };

  const items = [
    {
      icon: IconOverview,
      label: "Dashboard",
      // route: routes.overview,
      key: "overview",
    },
    {
      icon: IconFollowUp,
      label: "Follow-up",
      key: "follow-up",
    },
    {
      icon: IconSurvey,
      label: "Reports",
      key: "survey-report",
      children: [
        {
          icon: IconFollowUp,
          label: "Survey Report",
          key: "survey-report",
          // route: routes.surveyReport,
        },
        //{
        //	icon: IconFollowUp,
        //	label: "Follow Up Report",
        //	key: "2",
        //	route: "#",
        //},
        {
          icon: IconContact,
          label: "Contact Report",
          route: "/contact-report",
          key: "contact-report",
        },

       // {
        //	icon: IconFollowUp,
        //	label: "Event",
        //	key: "event",
        //	route: "/event",
        //},
        //{
        //	icon: IconFollowUp,
        //	label: "Attentdance",
        //	key: "4",
        //	route: "#",
        //},
      ],
    },

    //	{
    //		icon: IconContact,
    //		label: "Contact Report",
    //		route: "/contact-report",
    //		key: "contact-report",
    //	},
  ];

  const username = sessionStorage.username;

  const logout = () => {
    sessionStorage.clear();
    resetState();
    navigate(utils.isMobile() ? routes.mobileMenu : routes.home);
  };

  return (
    <div className="default layout">
      <Layout className={className || undefined}>
        <Sider
          collapsedWidth="90"
          collapsed={collapsed}
          width="300"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <div className="logo">
            <img src={Logo} alt="logo" />
            <span>Progress Singapore Party</span>
          </div>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[selectedKey]}
            items={items.map((item, index) => ({
              index: index,
              key: item.key,
              icon: React.createElement(item.icon),
              label: item.label,
              route: item.route,
              children: item.children,
            }))}
            onClick={(item) => {
              navigate(mapRoutes[item.key]);
            }}
          />
        </Sider>
        <Layout>
          {!hideMenu && (
            <Header className="site-layout-sub-header-background">
              <Button
                icon={<IconMenu />}
                type="text"
                onClick={() => setCollapsed(!collapsed)}
              />
              <h1>{title || ""}</h1>
              {showIcon && (
                <div className="logo-icon">
                  <img src={LogoIcon} alt="logo" />
                </div>
              )}
              <Popover content={<Button onClick={logout}>Log Out</Button>}>
                <div className="user">
                  <div className="avatar">
                    <img src={avatar} alt="avatar" />
                  </div>
                  <div className="username">{username}</div>
                  <CaretDownOutlined />
                </div>
              </Popover>
            </Header>
          )}
          <Content
            style={{
              margin: "24px 16px 0",
            }}
          >
            <div
              className="site-layout-background"
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              {children}
            </div>
            <Footer>© 2023 Progress Singapore Party</Footer>
          </Content>
        </Layout>
        <Drawer
          className="main-menu-drawer"
          placement="left"
          open={!collapsed}
          onClose={() => {
            setCollapsed(true);
          }}
        >
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={[selectedKey]}
            items={items.map((item, index) => ({
              index: index,
              key: item.key,
              icon: React.createElement(item.icon),
              label: item.label,
              children: item.children,
            }))}
            onClick={(item) => {
              navigate(mapRoutes[item.key]);
            }}
          />
        </Drawer>
      </Layout>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    appState: state,
  };
}

const mapDispatchToProps = (dispatch) => ({
  change: (data) => dispatch(change(data)),
  changeProfile: (data) => dispatch(changeProfile(data)),
  changeUser: (data) => dispatch(changeUser(data)),
  resetState: () => {
    dispatch(resetCounter());
    dispatch(resetCrm());
    dispatch(resetNote());
    dispatch(resetUser());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
