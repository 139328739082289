import _ from "lodash";
import moment from "moment";
import Layout from "layouts";
import { PageHeader } from "antd";

import DataTable from "./DataTable";
import "./survey-report.less";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "api/axios";
import { setData } from "app/analytic";
import { Button } from "antd";

const PageSize = 10;
const ANALYTIC_URL = "analytics2";

const ContactReportPage = ({ appState, setAnalyticDataToState }) => {
	const { analytic, identity } = appState;
	console.log("analytic", analytic);
	const [pageData, setPageData] = useState({
		rawData: analytic.data,
		data: analytic.data,
		pageCount: Math.ceil(analytic.data.length / PageSize),
		isLoading: true,
		filter: {
			selectedBlock: null,
			date_from: null,
			date_to: null,
		},
	});
	const [tableData, setTableData] = useState(pageData.rawData);

	useEffect(() => {
		const fetchAnalyticData = async (myToken) => {
			const response = await axios.get(
				ANALYTIC_URL,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Token " + myToken,
					},
				},
				{ withCredentials: true },
			);
			var data = response.data.data;



// capture only relevant contact details
			var consolidated_set = new Set()
			var temp_data = data.filter(d => {
				return d.mobile_telegram != null && d.mobile_telegram != "";
			});

			for (const i in temp_data)     consolidated_set.add(temp_data[i])

			var temp_data = data.filter(d => {
				return d.follow_up == "Yes" && d.follow_up != "None";
			});

			for (const i in temp_data)     consolidated_set.add(temp_data[i])

			var temp_data = data.filter(d => {
				return d.other_contact != null && d.other_contact != "";
			});

			for (const i in temp_data)     consolidated_set.add(temp_data[i])
			data = Array.from(consolidated_set)

			function comparesurveydate( a, b ) {
			  if ( a.survey_date < b.survey_date ){
			    return 1;
			  }
			  if ( a.survey_date > b.survey_date ){
			    return -1;
			  }
			  return 0;
			}

			data.sort( comparesurveydate );

		
//


			setPageData({
				rawData: data,
				data: data,
				pageCount: Math.ceil(data.length / PageSize),
				isLoading: false,
			});
			setTableData(data);
			//setAnalyticDataToState(data);
		};
		if (analytic.isLoading && pageData.isLoading && identity.token) {
			fetchAnalyticData(identity.token);
		}
	}, [identity, analytic, pageData, setAnalyticDataToState]);

	const handleChangeFilter = (filter) => {
		const newFilter = _.assign(pageData.filter, filter);
		const newData = filterData(newFilter);
		setPageData(
			_.assign(pageData, {
				data: newData,
				filter: newFilter,
			}),
		);
		setTableData(newData);
	};

	const filterData = (filter) => {
		let filteredData = pageData.rawData;
		if (filter.selectedBlock) {
			filteredData = filteredData.filter(
				(item) => item.block === filter.selectedBlock,
			);
		}
		console.log(filter);
		if (filter.date_from && filter.date_to) {
			filteredData = filteredData.filter(
				(item) =>
					new Date(item.survey_date) >= filter.date_from &&
					new Date(item.survey_date) <= filter.date_to,
			);
		}
		return filteredData;
	};
	const allBlocks = _.uniq(pageData.rawData.map((item) => item.block)).sort(
		(a, b) => a - b,
	);
	const dateRanges = _.uniq(
		pageData.rawData.map((item) =>
			moment(item.survey_date).format("YYYY-MM-DD"),
		),
	);

	// Todo: Instead of hardcode, to dynamically 
	// const downloadtitle1 = `${process.env.REACT_APP_API_BASEURL}/case/`
	const exportReportButton =<Button 
		type="default" onClick={() => {
			window.open("https://cdss.ourpsp.org/surveys/report/", "_blank") 
	  	}}>
			Download Report
		</Button>
	return (
		tableData && (
			<Layout className="survey-report-page" selectedKey="contact-report">
				<PageHeader
					ghost={false}
					onBack={false}
					title="CDSS: Contact Report"
					extra={exportReportButton}
				></PageHeader>
				
				<DataTable
					data={pageData.data}
					blocks={allBlocks}
					dateRanges={dateRanges}
					changeFilter={handleChangeFilter}
					selectedBlock={pageData?.filter?.selectedBlock}
				/>
			</Layout>
		)
	);
};

function mapStateToProps(state) {
	return {
		appState: state,
	};
}
const mapDispatchToProps = (dispatch) => ({
	setAnalyticDataToState: (data) => dispatch(setData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactReportPage);
