import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import './react-vis.css';
import {
  XYPlot,
  LineSeries,
  HorizontalBarSeries,
  RadialChart,
  Borders,
  Treemap,

  } from 'react-vis';
import Sidebar from './Sidebar'
import Dashboard from './Dashboard'
import TopBar from './TopBar'
import ProfileDashboard from './ProfileDashboard'

//https://uber.github.io/react-vis/documentation/general-principles/style

export default class Profile extends Component {

    constructor(props) {
        super(props);

    }

    render() {


        return (
          <div className="container">
          <div id="nav">
              <TopBar />
          </div>

          <div id="sidebar">
            <Sidebar display="Profile"/>
          </div>
          <div id="main">

              <ProfileDashboard />

          </div>



          </div>
            )


      }

  }
