import React, {Component} from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
//https://uber.github.io/react-vis/documentation/general-principles/style

export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Ground_Ops_container: false,
      Membership_visability: false,
      Sentiment_visability: false,
    };
  }

  ManageSidebar = (key, e) => {
    let stuff = this.state[key];
    console.log(key)
    stuff = !stuff;
    if (stuff === true) {
      e.target.classList.add("selected");
    } else {
      e.target.classList.remove("selected");
    }

    if (key === "Ground_Ops_container") {
      this.setState({
        Ground_Ops_container: stuff,
        Membership_visability: false,
        Sentiment_visability:false
      });

      document
        .getElementById("Membership_visability")
        .classList.remove("selected");
    }
    if (key === "Membership_visability") {
      this.setState({
        Ground_Ops_container: false,
        Membership_visability: stuff,
        Sentiment_visability:false
      });
      document
        .getElementById("Ground_Ops_container")
        .classList.remove("selected");
    }
    if (key === "Sentiment_visability") {
      this.setState({
        Ground_Ops_container: false,
        Membership_visability: false,
        Sentiment_visability:stuff,
      });
      document
        .getElementById("Ground_Ops_container")
        .classList.remove("selected");
    }

    //Do stuff
  };

  collape = e => {
    this.ManageSidebar(e.target.id, e);
  };

  render() {
    console.log(this.props)
    return (
      <div className="container sidebar">
        <div className="sidebar_box">
          <Link to="/index">
            <div className="sidebard_main_menu">
              <div id="Ground_Ops_container" onClick={this.collape}>
                Ground Ops
              </div>
            </div>
          </Link>
          {this.state.Ground_Ops_container === true ? (
            <div className="submenu_motion">
              <motion.div
                animate={{y: [-50, 0, 0]}}
                transition={{ease: "easeOut", duration: 1}}
              >
                <div>
                  <Link to="/activities">
                    <div className="sidebard_sub_menu">Activities</div>
                  </Link>
                  <Link to="/surveydata">
                    <div className="sidebard_sub_menu">Surveyed Data</div>
                  </Link>
                  <Link to="/profile">
                    <div className="sidebard_sub_menu">Profile</div>
                  </Link>
                </div>
              </motion.div>
            </div>
          ) : (
            <div></div>
          )}
          {this.props.display ? (
            <Link to="/index">
              <div className="sidebard_sub_menu selected">
                {this.props.display}
              </div>
            </Link>
          ) : (
            <div></div>
          )}

          <div>
            <br />
            <Link to="/membership">
              <div id="1" className="sidebard_main_menu">
                <div id="Membership_visability" onClick={this.collape}>
                  Membership
                </div>
              </div>
            </Link>
          </div>
          {this.state.Membership_visability === true ? (
            <motion.div
              animate={{y: [-50, 0, 0]}}
              transition={{ease: "easeOut", duration: 1}}
            >
              <Link to="/index">
                <div className="sidebard_sub_menu">Profile</div>
              </Link>
            </motion.div>
          ) : (
            <div></div>
          )}
          <br />
          <div>
            <Link to="/sentiments">
          <div id="3" className="sidebard_main_menu">
            <div id="Sentiment_visability" onClick={this.collape}>
              Sentiments
            </div>
          </div>
          </Link>
          </div>
        </div>
      </div>
    );
  }
}
