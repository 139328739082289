import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	token: "",
	profile: {},
};
export const identitySlice = createSlice({
	name: "identity",
	initialState,
	reducers: {
		reset: () => initialState,
		change: (state, action) => {
			state.token = action.payload.token;
		},
		changeProfile: (state, action) => {
			state.profile = action.payload.profile;
		},
		increment: (state) => {
			state.value += 1;
		},
		decrement: (state) => {
			state.value -= 1;
		},
		incrementByAmount: (state, action) => {
			state.value += action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	reset,
	change,
	changeProfile,
	increment,
	decrement,
	incrementByAmount,
} = identitySlice.actions;

export default identitySlice.reducer;
