import _ from "lodash";
import { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import axios from "api/axios";
import { connect } from "react-redux";

const CASE_NOTE_URL = "case_note/";

const NoteCell = ({ type, data, refresh, onOpen }) => {
	const [open, setOpen] = useState(false);
	if (!data || _.isEmpty(data)) return "";
	const handleOpen = (val) => {
		setOpen(val);
		onOpen();
	};

	const update = async (data) => {
		const response = await axios.post(
			CASE_NOTE_URL,
			JSON.stringify(data),
			{
				headers: {
					"Content-Type": "application/json",
				//	"Access-Control-Allow-Origin": "*",
					Authorization: "Token " + sessionStorage.sessiontoken,
				},
			},
			{ withCredentials: true },
		);
		return response;
	};
	const updateNote = (data) => {
		update(data).then((res) => {
			if (res.status === 200) {
				refresh();
			}
		});
	};

	let txt = type === "comment" && data ? data : "...";
	txt = txt.length > 20 ? txt.substring(0, 19) + "..." : txt;
	return (
		<div className="note-cell">
			<span className="text">{txt}</span>
			<Button icon={<PlusOutlined />} onClick={() => handleOpen(true)}></Button>
			{open && (
				<NoteDropdown
					setOpen={handleOpen}
					type={type}
					data={data}
					updateNote={updateNote}
				/>
			)}
		</div>
	);
};

const NoteDropdown = ({ setOpen, type, data, updateNote, appState }) => {
	if (type === "follow-up") {
		return (
			<FollowUpNotes setOpen={setOpen} data={data} updateNote={updateNote} />
		);
	}
	return <SimpleNote setOpen={setOpen} data={data} />;
};

const SimpleNote = ({ setOpen, data }) => (
	<>
		<div
			className="note-cell-dropdown-wrapper"
			onClick={() => setOpen(false)}
		></div>
		<div className="note-cell-dropdown simple">
			<div className="note-cell-dropdown-head">
				<span className="text">...</span>
				<Button
					icon={<MinusOutlined />}
					onClick={() => setOpen(false)}
				></Button>
			</div>
			<div className="note-cell-dropdown-content">
				<p>{data ? data : "Note goes here"}</p>
			</div>
		</div>
	</>
);

const DetailNote = ({ data, handleSubmit }) => {
	const [location, setLocation] = useState(data.location);
	const [details, setDetails] = useState(data.details);
	const [rep, setRep] = useState(data.rep);
	const [support, setSupport] = useState(data.support);

	const handleChangeNotecell = () => {
		handleSubmit({
			note_id: data.note_id,
			case_id: data.case_id,
			details,
			location,
			next_action: data.next_action,
			rep,
			support,
			type: data.type,
		});
	};
	return (
		<div className="form-content">
			<div className="form-content-header">
				<h3>{data?.type}</h3>
				<p>
					Action date: <span>{data?.updated_at}</span>
				</p>
			</div>
			<Form layout="vertical">
				<Form.Item value={location}>
					<Input
						placeholder="location/date"
						value={location}
						onChange={(e) => {
							setLocation(e.target.value);
						}}
					/>
				</Form.Item>
				<Form.Item>
					<Input.TextArea
						placeholder="details"
						rows={3}
						value={details}
						onChange={(e) => {
							setDetails(e.target.value);
						}}
					/>
				</Form.Item>
				<Form.Item>
					<Form.Item
						style={{
							display: "inline-block",
							width: "calc(50% - 5px)",
							marginRight: 10,
						}}
					>
						<Select
							value={rep}
							onChange={(value) => {
								setRep(value);
							}}
							placeholder="Block Rep?"
						>
							<Select.Option value={null}>Block Rep?</Select.Option>
							<Select.Option value="1">Yes</Select.Option>
							<Select.Option value="2">No</Select.Option>
							<Select.Option value="3">Maybe</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						style={{ display: "inline-block", width: "calc(50% - 5px)" }}
					>
						<Select
							value={support}
							onChange={(value) => {
								setSupport(value);
							}}
						>
							<Select.Option value={null}>Support Level?</Select.Option>
							<Select.Option value="1">Red</Select.Option>
							<Select.Option value="2">Yellow</Select.Option>
							<Select.Option value="3">Green</Select.Option>
							<Select.Option value="4">NA</Select.Option>
						</Select>
					</Form.Item>
				</Form.Item>
				<Form.Item className="actions">
					<Button type="primary" onClick={handleChangeNotecell}>
						Save
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

const FollowUpNotes = ({ setOpen, data, updateNote }) => {
	const [notes, setNotes] = useState(data);
	const handleSubmit = (data) => {
		const updatedNotes = notes.map((note) => {
			const newNote = _.cloneDeep(note);
			if (data.note_id === data.pk && data.case_id === note.fields.case) {
				newNote.fields.details = _.assign(note.fields.details, {
					details: data.details,
					location: data.location,
					rep: data.rep,
					support: data.support,
				});
			}
			return newNote;
		});
		setNotes(updatedNotes);
		updateNote(data);
		setOpen(false);
	};

	const renderedNotes = data.map((item) => {
		return <DetailNote data={item} handleSubmit={handleSubmit} />;
	});

	return (
		data && (
			<>
				<div
					className="note-cell-dropdown-wrapper"
					onClick={() => setOpen(false)}
				></div>
				<div className="note-cell-dropdown">
					<div className="note-cell-dropdown-head">
						<span className="text">...</span>
						<Button
							icon={<MinusOutlined />}
							onClick={() => setOpen(false)}
						></Button>
					</div>
					<div className="note-cell-dropdown-content">{renderedNotes}</div>
				</div>
			</>
		)
	);
};

function mapStateToProps(state) {
	return {
		appState: state,
	};
}

export default connect(mapStateToProps)(NoteCell);
