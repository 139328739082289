import {useState, useEffect} from "react";
import "./CRM.css";
import {faChartPie} from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export default function SideNav(props) {
  const MyStyle = ref => {
    if (props.active == ref) {
      return {
        color: "white",
        fontWeight: "bolder"
      };
    } else return null;
  };

  const showActive = ref => {
    if (props.active == ref) {
      return "row active";
    } else return "row";
  };

  return (
    <div className="sidenav">
      <Link className="sidebar__link" to="/db">
        <div className={showActive("Dashboard")}>
          <div className="col-sm-3">
            {" "}
            <FontAwesomeIcon icon={faChartPie} />
          </div>
          <div className="col-sm-9" style={MyStyle("Dashboard")}>
            Overview
          </div>
        </div>
      </Link>

      {/*

      <Link className="sidebar__link" to="/event">
        <div className={showActive("Event")}>
          <div className="col-sm-3">
            {" "}
            <FontAwesomeIcon icon={faCalendar} />
          </div>

          <div className="col-sm-9 " style={MyStyle("Event")}>
            {" "}
            Event
          </div>
        </div>
      </Link>

      */}

      <Link className="sidebar__link" to="/crm">
        <div className={showActive("Follow-up")}>
          <div className="col-sm-3">
            {" "}
            <FontAwesomeIcon icon={faThumbsUp} />
          </div>

          <div className="col-sm-9" style={MyStyle("Follow-up")}>
            Follow-up
          </div>
        </div>
      </Link>

      <Link className="sidebar__link" to="/reports">
        <div className={showActive("Report")}>
          <div className="col-sm-3">
            {" "}
            <FontAwesomeIcon icon={faFile} />
          </div>

          <div className="col-sm-9" style={MyStyle("Report")}>
            Survey Report
          </div>
        </div>
      </Link>

      <Link className="sidebar__link" to="/contact_report">
        <div className={showActive("ContactReport")}>
          <div className="col-sm-3">
            {" "}
            <FontAwesomeIcon icon={faFile} />
          </div>

          <div className="col-sm-9" style={MyStyle("ContactReport")} >
            Contact Report
          </div>
        </div>
      </Link>
    </div>
  );
}
