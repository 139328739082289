import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Activities from "./components/Activities";
import Login from "./components/Login";
import Surveyeddata from "./components/SurveyedData";
import Profile from "./components/Profile";
import Sentiments from "./components/Sentiments";
import Membership from "./components/Membership";
import CRM from "./components/CRM";

import OldDashboard from "./components/analytics/AnalyticDashoard";
import Dashboard from "./pages/dashboard";
import NewHome from "./components/Newhome";
import Reports from "./components/reports/Reports";
import SurveyReports from "./pages/SurveyReport";
// import EventTable from "./components/Event";
import EventTable from "./pages/Event/EventTable";
import Event from "./pages/Event";
import "bootstrap/dist/css/bootstrap.min.css";
import OldContactReport from "./components/reports/ContactReport";
import ContactReport from "./pages/ContactReport";
import CRMT from "./components/CRM2";
import MainMenu from "./pages/MainMenu";
import MobileMenuPage from "./pages/MobileMenu";
import { routes } from "./routes";

function App() {
  //sessionStorage.setItem('mytoken','' )
  return (
    <Router>
      <div className="main">
        <Routes>
          <Route exact path="/" element={<MainMenu />} />
          <Route path={routes.menu} exact element={<MainMenu />} />
          <Route path={routes.mobileMenu} exact element={<MobileMenuPage />} />

          {/* <Route exact path="/" element={<NewHome />} /> */}
          <Route path="/crm2" element={<CRMT />} />

          <Route path="/n" element={<Login />} />
          <Route path="/reports" element={<Reports />} />
          <Route path={routes.surveyReport} element={<SurveyReports />} />
          <Route path="/contact_report" element={<OldContactReport />} />
          <Route path={routes.contactReport} element={<ContactReport />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/db" element={<OldDashboard />} />
          <Route path="/crm" element={<CRM />} />
          <Route path="/index" element={<Home />} />
          <Route path="/sentiments" element={<Sentiments />} />
          <Route path="/activities" element={<Activities />} />
          <Route path="/surveydata" element={<Surveyeddata />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/membership" element={<Membership />} />
          <Route path={routes.event} element={<Event />} />
          <Route path="/event_table" element={<EventTable />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
