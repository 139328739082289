import {useState, useEffect, useContext, Component, useMemo} from "react";
import "./CRM.css";
import CrmCase from "./CRMcase";
import Button from "react-bootstrap/Button";
import TopBar from "./TopBar";
import SideNav from "./SideNav";
import AuthContext from "../context/AuthProvider";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "../api/axios";

import {connect} from "react-redux";

import Pagination from "../Pagination";

const CASE_URL = "/case";
let PageSize = 5;

export default function CRMT() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [crmData, setCrmData] = useState([])

  const user_level = JSON.parse(sessionStorage.myProfile).user_level;
  const currentUsers = JSON.parse(sessionStorage.users);
  const data = [
    {
      id: 1,
      status: "New",
      ward: "NgeeSoon",
      block: 123,
      floor: 3,
      unit: 22,
      mobile: 91245321,
      handler: "luc",
      created_at: "",
      action_details: []
    },
    {
      id: 2,
      status: "New",
      ward: "KYM",
      handler: "mengwee",
      mobile: 88885321,
      created_at: "",
      action_details: [
        {id: 3, description: "notes for 1", type: "Visit", date: "12/12/22"},
        {
          id: 4,
          description: "more notes for 2",
          type: "Telecom",
          updated_at: "1/2/21"
        }
      ]
    },
    {
      id: 3,
      status: "New",
      ward: "WCP",
      handler: null,
      created_at: "",
      action_details: []
    },
    {
      id: 4,
      status: "New",
      ward: "WCP",
      handler: "luc",
      created_at: "",
      action_details: []
    },
    {
      id: 5,
      status: "New",
      ward: "WCP",
      handler: null,
      created_at: "",
      action_details: []
    },
    {
      id: 6,
      status: "New",
      ward: "WCP",
      handler: "luc",
      created_at: "",
      action_details: []
    },

    {
      id: 7,
      created_at: "",
      status: "Progress",
      ward: "KYM",
      handler: "kevin",
      action: "House Visit",
      action_details: []
    },
    {
      id: 8,
      status: "New",
      ward: "WCP",
      handler: null,
      created_at: "",
      action_details: []
    },
    {
      id: 9,
      status: "New",
      ward: "WCP",
      handler: "luc",
      created_at: "",
      action_details: []
    },
    {
      id: 10,
      status: "New",
      ward: "WCP",
      handler: null,
      created_at: "",
      action_details: []
    },
    {
      id: 11,
      status: "New",
      ward: "WCP",
      handler: "luc",
      created_at: "",
      action_details: []
    }
  ];

  const addCase = () => {
    console.log("add case");
    alert("This feature is being implemented. KIV for now.");
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return crmData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);




  const getCases = async (myToken) => {
    try {
    axios.get(
        CASE_URL,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + myToken

          }
        },
        {withCredentials: true}
      ).then(response=> {setCrmData(response.data)});


//setCrmData(response.data)
setIsLoading(false)



    } catch (error) {
      console.log("Error");
      //this.setState({ error, isLoading: false });

    }
  };


  useEffect(() => {
//getCases(sessionStorage.sessiontoken)

const response =  axios.get(
    CASE_URL,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + sessionStorage.sessiontoken

      }
    },
    {withCredentials: true}
  )
setCrmData(response.data)
setIsLoading(false)
    console.log("start");
console.log(crmData)


},[currentTableData]);


  return (
    <div className="crm-container">
      <div className="container">
        <TopBar where="Follow-up" />
        <div className="side-and-content">
          <SideNav active="Follow-up" />
          <div className="content-box">
            <table>
              <tbody>
                <tr>
                  <th className="add-case-container" colSpan="2">
                    <Button onClick={addCase}>
                      New Case <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </th>
                  <th> </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>
                    <small>Submission Date</small>
                  </th>
                  <th>Comment</th>
                  <th>Ward</th>
                  <th>Block</th>
                  <th>Floor</th>
                  <th>Unit</th>
                  <th>Mobile</th>
                  {user_level == 2 ? "" : <th>Follow-up IC</th>}
                  <th>Status</th>
                  {user_level == 0 ? "" : <th>Action</th>}
                  <th>Notes</th>
                </tr>
                {!isLoading ? (
                  currentTableData.map(item => (
                    <CrmCase
                      key={item.id}
                      data={item}
                      currentUsers={currentUsers}
                    />
                  ))
                ) : (
                  <tr>
                    <td>
                      <p>Loading...</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>?</div>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={page => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
