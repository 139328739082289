import {useState, useEffect} from "react";
import "./CRM.css";
import Button from "react-bootstrap/Button";

import axios from "../api/axios";

import {faPlus, faAngleDown, faMinus} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector, useDispatch} from "react-redux";

import {
  addNewNoteSlice,
  incremenNoteByAmount,
  toggleNoteOpen
} from "../app/noteSlice";

export default function CrmActionPanel(props) {
  const CASE_URL = "/case";
  const [value, setValue] = useState(null);
  const [loaded, setLoading] = useState(false);

  const [options_ic, set_options_ic] = useState(props.options);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_level = useSelector(state => state.identity.profile.user_level);

  const manageOption = () => {

    if (!props.disabled) setLoading(true);

    //console.log(activateNotes)
  };

  const manageChange = e => {
    console.log("start activate notes!");
    setValue(e.target.value);
    console.log(props.notelength);

    if (props.type == 2) {
      var currentLabel = props.options.find(x => x.value == e.target.value)
        .label;
      props.activateNotes(true);

      props.addNote(currentLabel);
      var data = {
        pk: -1,
        fields: {
          details: {
            type: currentLabel
          },
          updated_at: "xxx"
        }
      };

      if (props.notelength > 0) dispatch(incremenNoteByAmount(data));
      else dispatch(addNewNoteSlice([data]));
    }

    //dispatch(toggleNoteOpen(props.case_id));
    console.log("finish activate notes!");
  };

  const removeOption = () => {
    {
      alert ('This feature is not implemented yet')
      /*
      async function removeFromServer() {
const headers = {
  "Content-Type": "application/json",
  Authorization: "Token " + sessionStorage.mytoken
}
        let response = await fetch(`${process.env.REACT_APP_API_BASEURL}/case/` ,headers);
        response = await response.text();
        console.log(response)


      }

*/
    }

    try {
      const postData = {
        action: props.type == 3 ? "status" : "handler"
      };

      axios.post(
        CASE_URL + "/" + props.case_id,

        JSON.stringify(
          postData
          //postdata
        ),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + sessionStorage.mytoken
          }
        },

        //},
        {withCredentials: true}
      );

      console.log("finish");
    } catch (err) {
      console.log(err);
    }

    setLoading(false);

    setValue(null);
    //window.location.reload(false);

    navigate("/crm");
  };

  const changetheOption = e => {
    if (window.confirm("Go ahead and change this?") && props.case_id) {
      try {
        let postData = {
          handler: e.target.value
        };

        console.log(props.type);
        if (props.type == 3) {
          postData = {
            status: e.target.value
          };
        }

        console.log(sessionStorage.sessiontoken);
        console.log(postData);

        axios.post(
          CASE_URL + "/" + props.case_id,

          JSON.stringify(
            postData
            //postdata
          ),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + sessionStorage.sessiontoken
            }
          },

          //},
          {withCredentials: true}
        );

        setLoading(false);

        setValue(null);

          setLoading(true)
            //setLoading(false);
          //this.setState({error, isLoading: true});
          //navigate("/crm");
        //window.location.reload(false);
      } catch (error) {
        console.log("Error???");
        this.setState({error, isLoading: false});
      }
    } else {
      console.log("n");
    }
  };

  useEffect(() => {
    console.log(props.disabled)
  });
  return (
    <div className="crm-action-container">
      {!loaded && props.data && props.data.handler ? (
        <div className="select-container">
          <div>{props.type == 3 ? props.data.status : props.data.handler}</div>

          {props.type == 1 && user_level == 2 ? (
            ""
          ) : (
            <div style={{display: "flex"}}>
              <div>
                &nbsp;&nbsp;
                <Button onClick={manageOption} disabled={props.disabled}>
                  <FontAwesomeIcon icon={faAngleDown} />
                </Button>
              </div>
              <div>
                <Button onClick={removeOption} disabled={props.disabled}>
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <h2 onClick={manageOption} onChange={manageChange}>
          {loaded ? (
            <div className="select-container">
              <select
                onChange={e => {
                  changetheOption(e);
                }}
                name="placeholder"
              >
                <option>--select--</option>
                {options_ic.map(d => (
                  <option key={d.value} value={d.value}>{d.label}</option>
                ))}
                )
              </select>

              <div>
                {/*
                  value ? (
                  <div>
                    <Button >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </div>
                ) : (
                  ""
                )
            */}
              </div>
            </div>
          ) : (
            <div>
{props.disabled?'':
              <Button>
                <FontAwesomeIcon icon={faPlus} />
              </Button>}
            </div>
          )}
        </h2>
      )}
    </div>
  );
}
