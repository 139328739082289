import _ from "lodash";
import { Table, PageHeader, Form, Select, DatePicker } from "antd";

const FollowUpTable = ({
  data,
  changeDate,
  allLevels,
  selectedLevel,
  changeSelectedLevel,
  isFiltered, 
	selectedSubLevel, 

}) => {
  const supportGreen = data.filter((item) => item.support == "GREEN");
  const supportYellow = data.filter((item) => item.support == "YELLOW");
  const supportRed = data.filter((item) => item.support == "RED");
  //const mobile = data.filter((item) => item.mobile_telegram == true);
  const mobile = data.filter(
    (item) =>
      item.mobile_telegram != "" &&
      item.mobile_telegram != null &&
      item.mobile_telegram != "NA" &&
      item.mobile_telegram != "None"

  );
  const name_resp = data.filter(
    (item) =>
      item.family_name != "" &&
      item.family_name != "NA" &&
      item.family_name != null &&
      item.family_name != "None"
  );
  const handleFilterDate = (time) => {
    changeDate(time ? time.format("YYYY-MM-DD") : null);
  };

  const tableData = [
    {
      blocks: _.chain(data).map("block").uniq().value().length,
      surveyNumbers: data.length,
      skippedNumber: _.chain(data)
        .filter((item) => item.skip_survey)
        .value().length,
      fullNumber:
        data.length -
        _.chain(data)
          .filter((item) => item.skip_survey)
          .value().length,
      followUpUnits: _.chain(data)
        .filter((item) => item.follow_up == "Yes")
        .value().length,
      green: `${supportGreen.length} (${(
        (supportGreen.length / data.length) *
        100
      ).toFixed(2)}%)`,
      yellow: `${supportYellow.length} (${(
        (supportYellow.length / data.length) *
        100
      ).toFixed(2)}%)`,
      red: `${supportRed.length} (${(
        (supportRed.length / data.length) *
        100
      ).toFixed(2)}%)`,
      obtainedMobiles: mobile.length,
      obtainedNames: name_resp.length,
    },
  ];
  const columns = [
    {
      title: "Total Blocks Covered",
      align: "center",
      dataIndex: "blocks",
    },
    {
      title: "Total Number of Surveys",
      align: "center",
      dataIndex: "surveyNumbers",
    },
    {
      title: "Number of Skipped Surveys Units",
      align: "center",
      dataIndex: "skippedNumber",
    },
    {
      title: "Number of Full Surveys",
      align: "center",
      dataIndex: "fullNumber",
    },
    {
      title: "Follow-up Unit",
      align: "center",
      dataIndex: "followUpUnits",
    },
    {
      title: "Support Level (Full Survey - GREEN only)",
      align: "center",
      dataIndex: "green",
    },
    {
      title: "Support Level (Full Survey - YELLOW only)",
      align: "center",
      dataIndex: "yellow",
    },
    {
      title: "Support Level  (Full Survey - RED only)",
      align: "center",
      dataIndex: "red",
    },
    {
      title: "Mobile Numbers Obtained",
      align: "center",
      dataIndex: "obtainedMobiles",
    },
    {
      title: "Names Obtained",
      align: "center",
      dataIndex: "obtainedNames",
    },
  ];

  const options = allLevels.map((item) => (
    <Select.Option value={item.value}>{item.label}</Select.Option>
  ));
  return (

    <>
      <PageHeader
        ghost={false}
        onBack={false}
        title="Summary Statistics"
        extra={[
          <Form layout="inline">
            <Form.Item>
              <Select value={selectedLevel} onChange={changeSelectedLevel}>
                <Select.Option value={null}>All</Select.Option>
                {options}
              </Select>
            </Form.Item>
            {isFiltered?<Form.Item  label={selectedSubLevel}></Form.Item>
            :
            <p></p>}
            <Form.Item label="Filter Dates">
              <DatePicker onChange={handleFilterDate} />
            </Form.Item>
          </Form>,
        ]}
      ></PageHeader>
      <Table dataSource={tableData} columns={columns} scroll={{ x: "100%" }} />
    </>
  );
};

export default FollowUpTable;
