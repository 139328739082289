import React from "react";

import "./auth.less";

const AuthLayout = ({ children }) => {
	return (
		<div className="auth layout">
			<main>{children}</main>
			<footer>© 2022 Progress Singapore Party</footer>
		</div>
	);
};

export default AuthLayout;
