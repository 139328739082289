import { createSlice } from "@reduxjs/toolkit";
const initialState = {
	data: [],
	isLoading: true,
};
export const crm = createSlice({
	name: "crm",
	initialState,
	reducers: {
		reset: () => initialState,
		setCrmData: (state, action) => {
			state.data = action.payload;
			state.isLoading = false;
		},

		incremenNoteByAmount: (state, action) => {
			console.log("here??");
			console.log(action.payload);
			var temp = state.data;
			temp.unshift(action.payload);
			state.data = temp;
		},
		setLoading: (state, action) => {
			state.isLoading = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { reset, setCrmData, incremenNoteByAmount, setLoading } =
	crm.actions;

export default crm.reducer;
