import uaParser from "ua-parser-js";

const isMobile = () => {
  const ua = uaParser(window.navigator.userAgent),
    device = ua.device,
    type = device.type;

  return type ? type === "mobile" || type === "tablet" : false;
};

export const utils = { isMobile };
