import { configureStore } from "@reduxjs/toolkit";
import identityReducer from "./counterSlice";
import userReducer from "./userSlice";
import noteReducer from "./noteSlice";
import crmReducer from "./crm";
import analyticReducer from "./analytic";

export default configureStore({
  reducer: {
    identity: identityReducer,
    users: userReducer,
    notes: noteReducer,
    crm: crmReducer,
    analytic: analyticReducer,
  },
});
