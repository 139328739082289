import _ from "lodash";
import { Component } from "react";
import "./CRM.css";
import CrmCase from "./CRMcase";
// import Button from "react-bootstrap/Button";
import TopBar from "./TopBar";
import SideNav from "./SideNav";
// import AuthContext from "../context/AuthProvider";
import {
  faPlus,
  faEye,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPaginate from "react-paginate";
import axios from "../api/axios";
import { utils } from "app/utils";

import { connect } from "react-redux";
import "../pagination.scss";

import Layout from "layouts";
import { Table, Button, PageHeader, Select } from "antd";
import {
  PlusOutlined,
  SyncOutlined,
  FileSearchOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";

import { MoreOutlined } from "@ant-design/icons";
import NoteCell from "components/NoteCell";
import moment from "moment";

const CASE_URL = "/case";
let PageSize = 15;

export class CRM extends Component {
  // State will apply to the posts object which is set to loading by default
  state = {
    crmData: [],
    selectedStatus: "All",
    isLoading: true,
    errors: null,
    currentUsers: [],
    user_level: JSON.parse(sessionStorage.myProfile).user_level,
    pageCount: 1,
    itemOffset: 0,
    itemsPerPage: 10,
  };

  addCase = () => {
    console.log("add case");
    alert("This feature is being implemented. KIV for now.");
  };
  getCases = async (myToken) => {
    try {
      const response = await axios.get(
        CASE_URL,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token " + myToken,
          },
        },
        { withCredentials: true }
      );
      const rawData = this.parseRawData(response.data);

      this.setState(
        {
          rawData: rawData,
          crmData: this.filterDataByStatus(rawData, this.state.selectedStatus),
          isLoading: false,
          pageCount: Math.ceil(rawData.length / PageSize),
        },
        () => {
          console.log(this.state.isLoading);
        }
      );
    } catch (error) {
      console.log("Error");
      this.setState({ crmData: [], isLoading: false });
    }
  };

  parseRawData = (rawData) => {
    return rawData.map((note) => {
      let actionDate = moment(Date.now()).format("YYYY-MM-DD");
      const details = note.action_details
        ? note.action_details.map((item) => {
            actionDate = moment(item?.fields?.updated_at).format("YYYY-MM-DD");
            return {
              note_id: item.pk,
              case_id: item.fields.case,
              details: item.fields.details.details,
              location: item.fields.details.location,
              next_action: item.fields.details.next_action,
              rep: item.fields.details.rep,
              support: item.fields.details.support,
              type: item.fields.details.type,
              updated_at: actionDate,
            };
          })
        : [];
      const action = {
        selectedAction: null,
        // selectedAction: details[0]?.type,
        details,
      };
      return _.assign(_.cloneDeep(note), { action });
    });
  };

  addNewActionDetail = (item, val) => {
    if (window.confirm("Go ahead and add note?")) {
      let currentDetail = item?.action?.details[0];

      // item.action.selectedAction = null;
      item.action.selectedAction = val;

      let actionDate = moment(Date.now()).format("YYYY-MM-DD");
      const newDetail = {
        note_id: -1,
        case_id: item.id,
        details: null,
        location: null,
        next_action: null,
        rep: null,
        support: null,
        type: val,
        updated_at: actionDate,
      };
      if (!currentDetail || currentDetail.note_id === -1) {
        item.action.details[0] = newDetail;
      } else {
        item.action.details.unshift(newDetail);
      }

      const newRawData = _.cloneDeep(this.state.rawData).map((note) =>
        note.id === item.id ? item : note
      );
      const newCrmData = _.cloneDeep(this.state.crmData).map((note) =>
        note.id === item.id ? item : note
      );
      this.setState(
        {
          crmData: newCrmData,
          rawData: newRawData,
        },
        () => {
          console.log(this.state.isLoading);
        }
      );
      // setData(newData);
      // console.log("newData", newData);
    }
  };

  handleChangeCaseProperty = (id, updateBody) => {
    console.log("handleChangeCaseProperty", id, updateBody);
    // this.setState({ value: e.value })

    // Update to server
    const update = async (id, body) => {
      try {
        return await axios.post(
          CASE_URL + "/" + id,
          JSON.stringify(body),
          {
            headers: {
              "Content-Type": "application/json",
              //	"Access-Control-Allow-Origin": "*",
              Authorization: "Token " + this.state.token,
            },
          },

          //},
          { withCredentials: true }
        );
      } catch (error) {
        console.log("Error???", error);
      }
    };
    
    update(id, updateBody).then((res) => {
      console.log(res);
      this.refreshRawData(this.state.token);

      if (Object.keys(updateBody)[0] == 'handler') alert ('IC assigned successfully.')
      else alert ('Status changed sucessfully.')
    });

    // console.log(this.state.value);
  };

  refreshRawData = () => {
    this.getCases(this.state.token);
  };

  openDetail = (item) => {
    if (item.action.selectedAction === null) {
      item.action.selectedAction = item.action.details[0].type;
      const newData = _.cloneDeep(this.state.rawData).map((note) =>
        note.id === item.id ? item : note
      );
      console.log("openDetail", newData.length);
      this.setState(
        {
          crmData: this.filterDataByStatus(newData, this.state.selectedStatus),
          rawData: newData,
        },
        () => {
          console.log(this.state.isLoading);
        }
      );
    }
  };

  componentDidMount() {
    this.getCases(sessionStorage.sessiontoken);

    const myProfile = JSON.parse(sessionStorage.myProfile);
    var currentUsers = JSON.parse(sessionStorage.users);
    currentUsers.sort(function (a, b) {
      return a.username.localeCompare(b.username);
    });

    //var currentUsers = this.props.appState.users.data;
    if (myProfile.user_level > 0) {

     
      //console.log(currentUsers.filter(d=>{return d.user_profile.grc=="Pioneer"}))

      //currentUsers = currentUsers.filter(d => {
      //  return d.user_profile?.grc != null  ;
      //});
      currentUsers = currentUsers.filter((d) => {
        return d.user_profile?.grc === myProfile.grc;
      });

      //currentUsers = currentUsers.filter(d=>{return d.user_profile.grc=="Pioneer"})
      this.setState({
        currentUsers: currentUsers,
        token: sessionStorage.sessiontoken,
      });
    }
    //const endOffset = this.state.itemOffset + this.state.itemsPerPage;
  }

  filter = (state) => {
    let temp_data = this.filterDataByStatus(this.state.rawData, state);
    // if (state === "All")
    // 	var temp_data = this.state.rawData.filter((d) => {
    // 		return d;
    // 	});
    // else
    // 	var temp_data = this.state.rawData.filter((d) => {
    // 		return d.status == state;
    // 	});
    console.log(state, temp_data);
    this.setState({ crmData: temp_data, selectedStatus: state });
  };

  filterDataByStatus = (data, status) => {
    let temp_data = _.cloneDeep(data);
    if (status !== "All") {
      temp_data = temp_data.filter((d) => {
        return d.status === status;
      });
    }
    console.log("filterDataByStatus", status, data, temp_data);
    return temp_data;
  };

  render() {
    console.log();
    const handlePageClick = (event) => {
      console.log(PageSize);

      const newOffset = (event.selected * PageSize) % this.state.crmData.length;
      this.setState({ itemOffset: newOffset });
    };

    var { isLoading, crmData, CurrentCrmData, itemOffset } = this.state;

    const endOffset = itemOffset + PageSize;
    //const user_level = this.props.appState.identity.profile.user_level;
    const myProfile = this.props.appState.identity.profile;
    const myGRC = myProfile.grc;
    var mypageCount = Math.ceil(crmData.length / PageSize);
    if (myProfile.user_level > 0) {

   
      if (myProfile.user_level == 2) {
        
        console.log(2)
        var filteredCrmData = crmData.filter((d) => {
          return d.branch.toUpperCase() == myGRC.toUpperCase() && d.handler == sessionStorage.username;
        });

      } else
{
      var filteredCrmData = crmData.filter((d) => {
        return d.branch.toUpperCase() == myGRC.toUpperCase();
      });
    }
      mypageCount = Math.ceil(filteredCrmData.length / PageSize);

      var CurrentCrmData = filteredCrmData.slice(
        this.state.itemOffset,
        endOffset
      );

      //this.setState({pageCount:Math.ceil(CurrentCrmData.length/PageSize)})
    } else {
      var filteredCrmData = crmData
      var CurrentCrmData = crmData.slice(this.state.itemOffset, endOffset);
    }

    return (
      <>
        <Layout
          className="follow-up-page"
          selectedKey="follow-up"
          title=""
        >
          <PageHeader
            key="follow-up-header"
            ghost={false}
            onBack={false}
            title="CDSS: Follow-up"
            subTitle="Assign Follow-up IC, update Status and add Action Notes"
            extra={[
              <Button key="1" icon={<PlusOutlined />} onClick={this.addCase}>
                Add New Case
              </Button>,
              <Button
                onClick={() => {
                  this.filter("New");
                }}
                key="2"
                icon={<FileSearchOutlined />}
                type={
                  this.state.selectedStatus === "New" ? "primary" : undefined
                }
              >
                New Case
              </Button>,
              <Button
                onClick={() => {
                  this.filter("Complete");
                }}
                //onClick={() => handleChangeFilter({ status: "Complete" })}
                key="3"
                icon={<FileDoneOutlined />}
                type={
                  this.state.selectedStatus === "Complete"
                    ? "primary"
                    : undefined
                }
              >
                Completed Cases
              </Button>,
              <Button
                onClick={() => {
                  this.filter("Progress");
                }}
                //onClick={() => handleChangeFilter({ status: "Progress" })}
                key="4"
                icon={<FileSyncOutlined />}
                type={
                  this.state.selectedStatus === "Progress"
                    ? "primary"
                    : undefined
                }
              >
                Progress Cases
              </Button>,
              <Button
                onClick={() => {
                  this.filter("All");
                }}
                //onClick={() => handleChangeFilter({ status: null })}
                key="5"
                icon={<SyncOutlined />}
              >
                Reset
              </Button>,
            ]}
          ></PageHeader>
          <FollowUpTable
            key="follow-up-table"
            data={filteredCrmData}
            users={this.state.currentUsers}
            refresh={this.refreshRawData}
            user_level={this.state.user_level}
            updateProps={this.handleChangeCaseProperty}
            addNewActionDetail={this.addNewActionDetail}
            openDetail={this.openDetail}
          />
        </Layout>
      </>
    );

    return (
      <div className="crm-container">
        <div className="container">
          <TopBar where="Follow-up" />
          <div className="side-and-content">
            <SideNav active="Follow-up" />
            <div className="content-box">
              <table>
                <tbody>
                  <tr>
                    <th className="add-case-container" colSpan="8">
                      <Button onClick={this.addCase}>
                        <FontAwesomeIcon icon={faPlus} /> New Case
                      </Button>
                      <Button
                        onClick={(e) => {
                          this.filter("New");
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} /> "New" cases
                      </Button>
                      <Button
                        onClick={(e) => {
                          this.filter("Complete");
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} /> "Completed" cases
                      </Button>
                      <Button
                        onClick={(e) => {
                          this.filter("All");
                        }}
                      >
                        <FontAwesomeIcon icon={faCloudDownloadAlt} /> Reset
                      </Button>
                    </th>
                  </tr>
                  <tr>
                    <th>#</th>
                    <th>
                      <small>Submission Date</small>
                    </th>
                    <th>Comment</th>
                    <th>Ward</th>
                    <th>Block</th>
                    <th>Floor</th>
                    <th>Unit</th>
                    <th>Mobile</th>
                    {this.state.user_level == 2 ? "" : <th>Follow-up IC</th>}
                    <th>Status</th>
                    {this.state.user_level == 0 ? "" : <th>Action</th>}
                    <th>Notes</th>
                  </tr>
                  {!isLoading ? (
                    CurrentCrmData.map((item) => (
                      <CrmCase
                        key={item.id}
                        data={item}
                        currentUsers={this.state.currentUsers}
                      />
                    ))
                  ) : (
                    <tr>
                      <td>
                        <p>Loading...</p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <br />
              <div className="pagination-container">
                <ReactPaginate
                  className="pagination-item"
                  breakLabel="..."
                  nextLabel=" >"
                  onClick={handlePageClick}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={mypageCount}
                  previousLabel="< "
                  renderOnZeroPageCount={null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FollowUpTable = (props) => {
  const {
    data,
    users,
    refresh,
    updateProps,
    user_level,
    addNewActionDetail,
    openDetail,
  } = props;

console.log(data)
  const usersOptions = users.map((u) => ({
    value: u.id,
    label: u.username,
  }));
  const getUserIdByName = (name) => {
    if (!users) return name;
    const user = users.find((u) => u.username === name);
    return user?.id || name;
  };

  const statusOptions = [
    {
      value: "0",
      label: "New",
    },
    {
      value: "1",
      label: "Progress",
    },
    {
      value: "2",
      label: "Pending",
    },
    {
      value: "3",
      label: "Complete",
    },
  ];
  const getStatusId = (name) => {
    return statusOptions.find((o) => o.name === name)?.value || name;
  };

  const renderSelectBox = (options, value, onChange) => {
    const items = options.map((o) => (
      <Select.Option value={o.value}>{o.label}</Select.Option>
    ));
    return (
      <Select value={value} className="follow-up-ic-select" onChange={onChange}>
        {items}
      </Select>
    );
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
    },
    {
      title: "Submission Date",
      dataIndex: "created_at",
      //render: (val) => moment(val).format("YYYY-MM-DD HH:mm:ss"),
      render: (val) => moment(val).format("YYYY-MM-DD"),
    },
    {
      title: "Ward",
      dataIndex: "details",
      render: (item) => item?.ward,
    },
    {
      title: "Block",
      dataIndex: "block",
    },
    {
      title: "Floor",
      dataIndex: "floor",
    },
    {
      title: "Unit",
      dataIndex: "unit",
    },
    {
      title: "Name",
      dataIndex: "submitted_by",
      render: (submitted_by) => {
        const user = users ? users.find((u) => u.id === submitted_by) : {};
        return user ? user.username : "David";
      },
    },
    {
      title: "Contact",
      dataIndex: "details",
      render: (item) => item?.mobile,
    },
    {
      title: "Member Comments",
      width: 200,
      render: (item) => {
        return <NoteCell type="comment" data={item.details?.comment} />;
      },
    },

    //{
    //	title: "Remarks",
    //	width: 200,
    //	render: (item) => {
    //		return <NoteCell type="comment" data={item.details?.remarks} />;
    //	},
    //},

    {
      title: "Follow-up IC",
      width: 180,
      render: (item) =>
        user_level === 2
          ? item.handler
          : renderSelectBox(
              usersOptions,
              getUserIdByName(item.handler),
              (value) => {
                updateProps(item.id, { handler: value });
              }
            ),
    },
    {
      title: "Status",
      width: 180,
      render: (item) =>
        renderSelectBox(statusOptions, getStatusId(item.status), (value) => {
          updateProps(item.id, { status: value });
        }),
    },
    {
      title: "Action",
      width: 180,
      render: (item) => {
        console.log(
          "render",
          item.action.selectedAction,
          item.handler,
          item.status,
          item.action.selectedAction === null && item.handler && item.status
        );
        return (item.action.details &&
          item.action.details.length > 0 &&
          item.action.selectedAction !== null) ||
          (item.action.details.length === 0 && item.handler && item.status) ? (
          <Select
            value={item.action.selectedAction}
            onChange={(val) => addNewActionDetail(item, val)}
            className="follow-up-action-select"
            disabled={user_level === 0}
            placeholder="Start Action"
          >
            <Select.Option value="Phone Call">Phone Call</Select.Option>
            <Select.Option value="Messaging">Messaging</Select.Option>
            <Select.Option value="House Visit">House Visit</Select.Option>
            <Select.Option value="Meetup">Meetup</Select.Option>
            <Select.Option value="Others">Others</Select.Option>
          </Select>
        ) : (
          <></>
        );
      },
    },
    {
      title: "Notes",
      width: 300,
      render: (item) => {
        return (
          <NoteCell
            type="follow-up"
            data={item.action.details}
            onOpen={() => openDetail(item)}
            refresh={refresh}
          />
        );
      },
    },
    //{
    //	width: 30,
    //	align: "right",
    //	render: () => <Button icon={<MoreOutlined />} />,
    //},
  ];
  return (
    <Table
      dataSource={data}
      columns={columns}
      scroll={{ x: "100%" }}
      pagination={{ showQuickJumper: true, showSizeChanger: true }}
    />
  );
};

function mapStateToProps(state) {
  return {
    appState: state,
  };
}

export default connect(mapStateToProps)(CRM);
//export default crm;
