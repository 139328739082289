import _ from "lodash";
import moment from "moment";
import Layout from "layouts";
import { PageHeader, Row, Col, Form, Select } from "antd";
import { Pie } from "@ant-design/plots";
import DataTable from "./DataTable";

import "./overview.less";
import { connect } from "react-redux";
import { setData } from "app/analytic";
import { useEffect, useState } from "react";
import axios from "api/axios";

const ANALYTIC_URL = "analytics2";

var grc_options = [
	{ label: "WCP", value: "WCP" },
	{ label: "CCKHKN", value: "CCKHKN" },
	{ label: "NS", value: "NS" },
	{ label: "KYM", value: "KYM" },
	{ label: "BTP", value: "BTP" },

];
const OverviewPage = ({ appState, setAnalyticDataToState }) => {
	const { analytic, identity } = appState;
	var _data =
		analytic.data && Number(sessionStorage.user_level) > 0
			? analytic.data.filter((d) => {
					return d.grc.toUpperCase() == sessionStorage.myGRC.toUpperCase();
			  })
			: analytic.data;
	const [data, setData] = useState(_data);
	const [isFiltered, setIsFiltered] = useState(null);
	const [SubLevels, setSubLevels] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedLevel, setSelectedLevel] = useState(null);
	const [selectedSubLevel, setSelectedSubLevel] = useState(null);
	
	const [selectedDate, setSelectedDate] = useState(null);
	const levels =
		sessionStorage.user_level > 0
			? grc_options.filter((d) => {
					return d.value.toUpperCase() === sessionStorage.myGRC.toUpperCase();
			  })
			: grc_options;

	useEffect(() => {
		const fetchAnalyticData = async (myToken) => {
			const response = await axios.get(
				ANALYTIC_URL,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: "Token " + myToken,
					},
				},
				{ withCredentials: true },
			);
			const data = response.data.data;
			// setData(data);
			setIsLoading(false);
			setAnalyticDataToState(data);

			if (sessionStorage.user_level > 0) {
				var _data = data.filter((d) => {
					return d.grc.toUpperCase() == sessionStorage.myGRC.toUpperCase();
				});
				setData(_data);

			} else {
				setData(data);


			}
		};
		if (analytic.isLoading && isLoading && identity.token) {
			fetchAnalyticData(identity.token);
		}
	}, [identity, analytic, data, isLoading, setAnalyticDataToState]);

	const handleChangeLevel = (newVal) => {
	
		setSelectedLevel(newVal);
		filterData(newVal, selectedDate);
		// const filteredData = newVal
		// 	? _data.filter((item) => item.grc.toUpperCase() === newVal.toUpperCase())
		// 	: _data;
		// setData(filteredData);
	};

	const handleChangeSubLevel = (newVal) => {
		console.log(isFiltered)
		setSelectedSubLevel(newVal);
		filterData(selectedLevel, selectedDate, newVal );
		
		// const filteredData = newVal
		// 	? _data.filter((item) => item.grc.toUpperCase() === newVal.toUpperCase())
		// 	: _data;
		// setData(filteredData);
	};

	const handleChangeDate = (newVal) => {
		setSelectedDate(newVal);
		filterData(selectedLevel, newVal);
	};

	const filterData = (grc, time, district) => {
		if (!_data) return;
		console.log("filterData", grc, time, district);
		const filteredData = _data.filter((item) => {
			let filterGrc = grc ? item.grc.toUpperCase() === grc.toUpperCase() : true;
			let filterTime = time
				? moment(item.updated_at).format("YYYY-MM-DD") === time.toUpperCase()
				: true;
			let filterDistrict = district? item.ward.toUpperCase() === district.toUpperCase():true
			return filterGrc && filterTime && filterDistrict;
		});
		setData(filteredData);
		setSubLevels(_.uniq(filteredData.map((item) => (item.ward))).sort())
		setIsFiltered(grc)
		
	};

	var support = data.map((d) => d.support);
	var counts = { YELLOW: 0, GREEN: 0, RED: 0, NA: 0 };

	for (var i = 0; i < support.length; i++)
		counts[support[i]] = counts[support[i]] + 1 || 1;
	const countPercentate = {
		YELLOW: (counts["YELLOW"] / data.length) * 100,
		GREEN: (counts["GREEN"] / data.length) * 100,
		RED: (counts["RED"] / data.length) * 100,
		NA:
			((data.length - (counts["YELLOW"] + counts["GREEN"] + counts["RED"])) /
				data.length) *
			100,
	};
	return (
		data && (
			<Layout
				className="overview-page"
				selectedKey="overview"
				title="Dashboard & Analytics"
			>
				<PageHeader
					ghost={false}
					onBack={false}
					title="Overview (Charting/Summary)"
				></PageHeader>
				<div className="filter-section">
					<div className="filter-box">
						<Row>
							<Col md={24} lg={10} className="charts">
								<div className="support-level-chart">
									<SupportLevelChart data={countPercentate} />
								</div>
							</Col>
							<Col md={24} lg={14}>
								<SupportLevelExplain
									data={countPercentate}
									allLevels={levels}
									selectedLevel={selectedLevel}
									changeSelectedLevel={handleChangeLevel}
									isFiltered={isFiltered}
									SubLevels={SubLevels}
									changeSubSelectedLevel={handleChangeSubLevel}
									selectedSubLevel={selectedSubLevel}
								
								/>
							</Col>
						</Row>
					</div>
				</div>
				<DataTable
					data={data}
					changeDate={handleChangeDate}
					allLevels={levels}
					selectedLevel={selectedLevel}
					changeSelectedLevel={handleChangeLevel}
					isFiltered={isFiltered}
					selectedSubLevel={selectedSubLevel}
				/>
			</Layout>
		)
	);
};

const SupportLevelChart = ({ data }) => {
	const chartData = [
		{
			type: "Support Level  - YELLOW",
			color: "yellow",
			value: data["YELLOW"],
		},
		{
			type: "Support Level  - GREEN",
			color: "green",
			value: data["GREEN"],
		},
		{
			type: "Support Level  - RED",
			color: "red",
			value: data["RED"],
		},
		{
			type: "Support Level  - NA",
			color: "grey",
			value: data["NA"],
		},
	];
	const config = {
		legend: false,
		appendPadding: 10,
		data: chartData,
		angleField: "value",
		colorField: "type",
		radius: 0.9,
		color: ["#FFC300", "#79BC44", "#D73343", "#878484"],
		label: {
			type: "inner",
			offset: "-30%",
			content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
			style: {
				fontSize: 14,
				textAlign: "center",
			},
		},
		interactions: [
			{
				type: "element-active",
			},
		],
	};
	return <Pie {...config} />;
};

const SupportLevelExplain = ({
	data,
	allLevels,
	selectedLevel,
	changeSelectedLevel,
	isFiltered,
	SubLevels,
	changeSubSelectedLevel,
	selectedSubLevel,
	
}) => {
	const options = allLevels.map((item) => (
		<Select.Option value={item.value}>{item.label}</Select.Option>
	));
	const suboptions = isFiltered? SubLevels.map((item) => (
		<Select.Option value={item}>{item}</Select.Option>
	)):null;
	

	return (
		<div className="support-level-explain">
			<Form size="large">
				<Form.Item label="Support Level">
					<Select value={selectedLevel} onChange={changeSelectedLevel}>
						<Select.Option value={null}>All</Select.Option>
						{options}
					</Select>
				</Form.Item>
			</Form>
			{isFiltered?
			<Form size="large">
			<Form.Item label="">
				<Select value={selectedSubLevel} onChange={changeSubSelectedLevel}>
				<Select.Option value={null}>All</Select.Option>
					{suboptions}
				</Select>
			</Form.Item>
		</Form>:
			""}

			<div className="support-level-explain-box">
				<div className="support-level-explain-box-header">Full Survey</div>
				<div className="support-level-explain-box-content">
					<div className="statistic-box-wrapper">
						<div className="statistic-box yellow">
							<h4>
								Support Level - <span>YELLOW</span>
							</h4>
							<h3>{data["YELLOW"].toFixed(2)}%</h3>
						</div>
					</div>
					<div className="statistic-box-wrapper">
						<div className="statistic-box green">
							<h4>
								Support Level - <span>GREEN</span>
							</h4>
							<h3>{data["GREEN"].toFixed(2)}%</h3>
						</div>
					</div>
					<div className="statistic-box-wrapper">
						<div className="statistic-box red">
							<h4>
								Support Level - <span>RED</span>
							</h4>
							<h3>{data["RED"].toFixed(2)}%</h3>
						</div>
					</div>
					<div className="statistic-box-wrapper">
						<div className="statistic-box na">
							<h4>
								Support Level - <span>NA</span>
							</h4>
							<h3>{data["NA"].toFixed(2)}%</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

function mapStateToProps(state) {
	return {
		appState: state,
	};
}
const mapDispatchToProps = (dispatch) => ({
	setAnalyticDataToState: (data) => dispatch(setData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
