export const routes = {
  home: "/",
  login: "/n",
  loginDashboard: "/login-dashboard",
  menu: "/menu",
  mobileMenu: "/m-menu",
  overview: "/dashboard",
  followUp: "/crm",
  surveyReport: "/survey-reports",
  contactReport: "/contact-report",
  surveyList: "/survey/list",
  surveyForm: "/survey/form",
  surveyChooseBlock: "/survey/choose-block",
  event: "/event",
};
